<template>
  <div class="banner-box">
    <div class="banner-wrapper">
      <!-- 中心屏 -->
      <div class="container">
        <!-- 顶部面包屑 -->
        <div class="top-crumb">
          <el-link :underline="false" @click="handle('/home')">{{
            $t("首页")
          }}</el-link>
          <span>&gt;</span>
          <el-link v-if="detailData.treeId === 1" :underline="false" @click="handle('/index')">{{ $t("餐厅") }}</el-link>
          <el-link v-if="detailData.treeId === 2" :underline="false" @click="handle('/department')">{{ $t("观光情报") }}</el-link>
          <span>&gt; {{ detailData.storeName }}</span>
        </div>
        <div class="banner-content-box">
          <!-- 中间内容 -->
          <div class="center-info">
            <!-- 放图片 -->
            <div class="banner-left">
              <div v-if="detailData.imgList && detailData.imgList.length > 0" class="swiper-box">
                <div class="swiper-container img-top">
                  <div class="swiper-wrapper">
                    <el-image v-for="(imgItem, imgIndex) in detailData.imgList" :key="imgIndex" :src="imgItem" fit="contain" class="list-left-image swiper-slide" lazy></el-image>
                  </div>
                  <div class="swiper-button-prev swiper-button-white"></div>
                  <div class="swiper-button-next swiper-button-white"></div>
                </div>
                <div class="swiper-container img-thumbs">
                  <div class="swiper-wrapper">
                    <el-image v-for="(imgItem, imgIndex) in detailData.imgList" :key="imgIndex" :src="imgItem" class="list-image-thumbs swiper-slide" lazy fit="contain"></el-image>
                  </div>
                </div>
              </div>
              <!-- <el-image v-if="detailData.imgList && detailData.imgList.length > 0" :src="detailData.imgList[0]" class="banner-image" lazy></el-image>
              <div v-else class="not-image" /> -->
            </div>
            <!-- 放内容 -->
            <div class="banner-content">
              <div class="banner-content-title">
                <span>{{ detailData.storeName }}</span>
              </div>
              <el-row :gutter="30">
                <el-col :span="15">
                  <div class="store-rate mt-20">
                    <!-- <el-rate v-model="detailData.storeScore" disabled :show-score="false" text-color="#ff9900"  score-template="{value}"> </el-rate> -->
                  </div>
                  <div v-if="detailData.equipment" class="list-service">
                    <div v-for="(serviceItem, serviceIndex) in service" :key="serviceItem.id" class="service-item">
                      <span v-if="
                          detailData.equipment.indexOf(serviceItem.value) !== -1
                        ">
                        <el-image :src="serviceItem.icon" class="service-logo" lazy></el-image>
                        <span class="service-content">{{
                          $t(serviceItem.label)
                        }}</span>
                        <el-divider v-if="serviceIndex < service.length - 1" direction="vertical"></el-divider>
                      </span>
                    </div>
                  </div>
                </el-col>
                <el-col :span="5">
                  <div v-show="
                      detailData.recommendActivity &&
                        detailData.recommendActivity.indexOf('3') !== -1
                    " class="go-to-box">
                    <div>Go To トラベル</div>
                    <div>店舗</div>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="couponBox" v-if="
                      detailData.recommendActivity &&
                        detailData.recommendActivity.indexOf('5') !== -1
                    ">
                    <div class="coupon-warper">
                      <p>Double OFF</p>
                      <p class="couponValue">¥ 500</p>
                    </div>
                  </div>
                </el-col>
              </el-row>
              <div class="store-service" style="margin-top: 12px;">
                <el-row>
                  <el-col :span="24">
                    <div v-if="
                        detailData.storeBusinessTimeDtoList &&
                          detailData.storeBusinessTimeDtoList.length > 0
                      ">
                      {{ $t("营业时间") }}：
                      <span v-if="
                          detailData.storeBusinessTimeDtoList &&
                            detailData.storeBusinessTimeDtoList.length > 0
                        ">
                        <span v-for="(timeItem,
                          timeIndex) in detailData.storeBusinessTimeDtoList" :key="timeItem.id" style="margin-right: 6px">
                          <!-- <span>{{ $t(weeks[timeItem.weeks[0]]) }} ~ {{ $t(weeks[timeItem.weeks[timeItem.weeks.length - 1]]) }}</span> -->
                          <span v-for="(weekItem, weekIndex) in dealWeekDays(
                              timeItem.week
                            )" :key="weekIndex">
                            <!-- {{ weeks[weekItem] }} -->
                            <span v-if="weeks[weekItem].indexOf('~') > -1">{{ $t(weeks[weekItem].split("~")[0]) }}~{{
                                $t(weeks[weekItem].split("~")[1])
                              }}</span>
                            <span v-else>{{ $t(weeks[weekItem]) }}</span>
                            <span v-if="
                                weekIndex !==
                                  dealWeekDays(timeItem.week).length - 1
                              ">、</span>
                          </span>
                          <span style="margin: 0 6px">{{ timeItem.startTime | timeFilter }} -
                            {{ timeItem.endTime | timeFilter }}</span>
                          <el-divider v-if="
                              timeIndex <
                                detailData.storeBusinessTimeDtoList.length - 1
                            " direction="vertical"></el-divider>
                        </span>
                      </span>
                    </div>
                    <div v-if="detailData.treeId === 1" class="list-info-item mt-20">
                      {{ $t("类型") }}：
                      <span v-if="detailData.storeTypes && detailData.storeTypes.length > 0">
                        <span v-for="(type, typeIndex) in detailData.storeTypes" :key="typeIndex">
                          <span v-show="typeIndex > 0"></span>
                          {{ $t(shopType[type]) }}
                        </span>
                      </span>
                      <span v-else>-</span>
                    </div>
                    <div v-if="detailData.treeId === 1" class="list-detail mt-20">
                      <span>{{ $t("大阪乐游劵折扣率") }}：</span><span v-if="detailData.osakaSaleRate" class="const-red-content">{{ detailData.osakaSaleRate }}%</span><span v-else>-</span>
                      <el-divider direction="vertical" />
                      <!-- <span>JR ALLPASS {{ $t("折扣率") }}：</span><span v-if="detailData.jrSaleRate" class="const-red-content">{{ detailData.jrSaleRate }}%</span><span v-else>-</span>
                      <el-divider direction="vertical" />
                      <span>Tokyo Joyful {{ $t("折扣率") }}：</span><span v-if="detailData.tokyoSaleRate" class="const-red-content">{{ detailData.tokyoSaleRate }}%</span><span v-else>-</span> -->
                      <el-divider direction="vertical" />
                      <span>Tripellet {{ $t("折扣率") }}：</span><span v-if="detailData.tripelletSaleRate" class="const-red-content">{{ detailData.tripelletSaleRate }}%</span><span v-else>-</span>
                    </div>
                    <div v-if="detailData.treeId === 2" class="list-detail mt-20">
                      <span v-if="detailData.osakaSaleRate">{{ $t("大阪乐游劵折扣率") }}：</span><span v-if="detailData.osakaSaleRate" class="const-red-content">{{ detailData.osakaSaleRate }}%</span>
                      <el-divider v-if="detailData.osakaSaleRate" direction="vertical" />
                      <!-- <span v-if="detailData.jrSaleRate">JR ALLPASS {{ $t("折扣率") }}：</span><span v-if="detailData.jrSaleRate" class="const-red-content">{{ detailData.jrSaleRate }}%</span> -->
                      <el-divider v-if="detailData.jrSaleRate" direction="vertical" />
                      <!-- <span v-if="detailData.tokyoSaleRate">Tokyo Joyful {{ $t("折扣率") }}：</span><span v-if="detailData.tokyoSaleRate" class="const-red-content">{{ detailData.tokyoSaleRate }}%</span> -->
                      <el-divider v-if="detailData.tokyoSaleRate" direction="vertical" />
                      <span v-if="detailData.tripelletSaleRate">Tripellet {{ $t("折扣率") }}：</span><span v-if="detailData.tripelletSaleRate" class="const-red-content">{{ detailData.tripelletSaleRate }}%</span>
                    </div>
                    <div v-if="detailData.treeId === 1" class="list-detail mt-20">
                      <div class="detail-content">
                        <el-row v-if="detailData.address">
                          <el-col :span="2" :style="getAddressLabelStyle()">{{ $t("地址") }}：</el-col>
                          <el-col :span="21"><span style="white-space: pre-wrap;">{{
                              detailData.address
                            }}</span></el-col>
                        </el-row>
                        <span v-else>{{ $t("地址") }}：-</span>
                      </div>
                    </div>
                    <div v-if="detailData.treeId === 2" class="list-detail mt-20">
                      <div class="detail-content">
                        <el-row v-if="detailData.address">
                          <el-col :span="2" :style="getAddressLabelStyle()">{{ $t("地址") }}：</el-col>
                          <el-col :span="21"><span style="white-space: pre-wrap;">{{
                              detailData.address
                            }}</span></el-col>
                        </el-row>
                      </div>
                    </div>
                    <div v-if="detailData.treeId === 1" class="food-money">
                      <!-- <p v-if="detailData.breakfast > 0">{{ $t('早餐') }}：<span class="cost-text">¥ {{ detailData.breakfast | moneyFilter }}<span v-if="detailData.maxBreakfast"> - {{ detailData.maxBreakfast	| moneyFilter }}</span></span><el-divider direction="vertical"></el-divider></p> -->
                      <p v-if="detailData.lunch > 0">
                        {{ $t("午餐") }}：<span class="cost-text">¥ {{ detailData.lunch | moneyFilter
                          }}<span v-if="detailData.maxLunch">
                            - {{ detailData.maxLunch | moneyFilter }}</span></span>
                        <el-divider direction="vertical"></el-divider>
                      </p>
                      <p v-if="detailData.dinner > 0">
                        {{ $t("晚餐") }}：<span class="cost-text">¥ {{ detailData.dinner | moneyFilter
                          }}<span v-if="detailData.maxDinner">
                            - {{ detailData.maxDinner | moneyFilter }}</span></span>
                      </p>
                    </div>
                    <div class="list-detail mt-20">
                      <div class="detail-content">
                        <el-row v-if="detailData.featureProvide">
                          <span>{{ $t("特典提供") }}：</span><span style="white-space: pre-wrap;">{{
                            detailData.featureProvide
                          }}</span>
                        </el-row>
                        <span v-else></span>
                      </div>
                    </div>
                    <div v-if="detailData.treeId === 1" class="list-detail mt-20">
                      <div class="detail-content">
                        <el-row>
                          <span style="color:#ffbb2c;" v-for="(item,i) in Announcement " :key='i'>※{{item[lan]}}</span>
                        </el-row>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <!-- 底部内容 -->
          <div class="bottom-order"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dealWeekDay } from '@/utils/util'
import { weeks, storeShopType, storeService, storePayType, onlinePayType, qrPayType, homeBulletinList, Announcement } from '@/utils/public_data'
import { formatMoney } from '@/utils/money'
import Swiper from 'swiper'

export default {
  filters: {
    moneyFilter(val) {
      return formatMoney(val)
    },
    timeFilter(val) {
      return val.substring(0, 5)
    },
  },
  data() {
    return {
      detailData: {},
      // center: { lng: 139.764887, lat: 35.682084 },
      zooms: 15,
      active: 1,
      showFlag: false,
      weeks: weeks(),
      shopType: storeShopType(),
      service: storeService(),
      payType: storePayType(),
      onlineType: onlinePayType(),
      qrType: qrPayType(),
      buttetinList: homeBulletinList(),
      Announcement: Announcement(),
      lan: '',
    }
  },
  mounted() {
    this.language()
  },
  methods: {
    language() {
      this.lan = localStorage.getItem('locale')
      return localStorage.getItem('locale')
    },
    handle(val) {
      this.$router.push(val)
    },
    // 获取地址label样式，英文环境处理
    getAddressLabelStyle() {
      if (this.$i18n.locale === 'en') {
        return 'width: 76px;'
      }
      return 'width: 50px;'
    },
    initSwiper() {
      this.thumbsSwiper = new Swiper(`.img-thumbs`, {
        spaceBetween: 10,
        slidesPerView: 3,
        nextButton: `.swiper-button-next`,
        prevButton: `.swiper-button-prev`,
        loop: false,
        watchSlidesVisibility: true /*避免出现bug*/,
      })
      this.swiper = new Swiper(`.img-top`, {
        spaceBetween: 10,
        nextButton: `.swiper-button-next`,
        prevButton: `.swiper-button-prev`,
        speed: 1000,
        loop: false,
        controller: {
          control: this.thumbsSwiper,
        },
        thumbs: {
          swiper: this.thumbsSwiper,
        },
      })
    },
    dealWeekDays(list) {
      // console.log(dealWeekDay(list))
      return dealWeekDay(list)
    },
    init(data) {
      this.detailData = data
      let minPrice = this.detailData.breakfast
      let maxPrice = this.detailData.lunch
      if (minPrice == 0 || minPrice > this.detailData.dinner) {
        minPrice = this.detailData.dinner
      }
      if (minPrice == 0 || minPrice > this.detailData.lunch) {
        minPrice = this.detailData.lunch
      }
      if (maxPrice == 0 || maxPrice < this.detailData.dinner) {
        maxPrice = this.detailData.dinner
      }
      if (maxPrice == 0 || maxPrice < this.detailData.breakfast) {
        maxPrice = this.detailData.breakfast
      }
      this.$set(this.detailData, 'minPrice', minPrice)
      this.$set(this.detailData, 'maxPrice', maxPrice)
      if (this.detailData.storeImg) {
        this.$set(this.detailData, 'imgList', this.detailData.storeImg.split(','))
      }
      // console.log(this.detailData.imgList)
      if (this.detailData.imgList && this.detailData.imgList.length > 0) {
        this.$nextTick(() => {
          this.initSwiper()
        })
      }
      if (this.detailData.storeType) {
        this.$set(this.detailData, 'storeTypes', this.detailData.storeType.split(','))
      }
      if (this.detailData.stipulationRestDays) {
        // eslint-disable-next-line no-useless-escape
        var stipulationRestDaysArr = this.detailData.stipulationRestDays.split('//')
        this.$set(
          this.detailData,
          'restDay',
          stipulationRestDaysArr[0].replace('undefined', '').replace(/^,+/, '').split(',').concat(stipulationRestDaysArr[1])
        )
      }
      if (this.detailData.allowPayType) {
        this.$set(this.detailData, 'payTypes', this.detailData.allowPayType.split(','))
      }
      if (this.detailData.allowOnlinePay) {
        this.$set(this.detailData, 'onlineTypes', this.detailData.allowOnlinePay.split(','))
      }
      if (this.detailData.allowQrPay) {
        this.$set(this.detailData, 'qrTypes', this.detailData.allowQrPay.split(','))
      }
      if (this.detailData.storeDescribe) {
        this.$set(this.detailData, 'measures', this.detailData.storeDescribe.split(','))
      }
      if (this.detailData.storeBusinessTimeDtoList && this.detailData.storeBusinessTimeDtoList.length > 0) {
        this.detailData.storeBusinessTimeDtoList.map((item) => {
          this.$set(item, 'weeks', item.week.split(',').sort())
        })
        let startTime = this.detailData.storeBusinessTimeDtoList[0].startTime
        let endTime = this.detailData.storeBusinessTimeDtoList[0].endTime
        if (startTime && endTime) {
          this.$set(this.detailData, 'businessTimes', startTime + '~' + endTime)
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.banner-box {
  width: 100%;
  min-height: 450px;
  // background: url('../../assets/images/banner-02.png');
  // background: linear-gradient(to top left,#F7971E,#FFD200);
  .banner-wrapper {
    padding: 10px;
    background-color: rgba(245, 242, 245, 0.5);
    .top-crumb {
      margin-top: 10px;
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      font-size: 16px;
      ::v-deep .el-link {
        font-size: 16px;
        &:hover {
          color: #e7b530;
        }
      }
    }
    .banner-content-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      width: 100%;
      min-height: 400px;
      padding: 20px;
      box-sizing: border-box;
      background: #fff;
      border: 1px solid #dcdfe6;
      border-radius: 5px;
      .bottom-order {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        .detail-content {
          display: flex;
          justify-content: flex-start;
        }
      }
      .top-crumb span {
        padding: 0 6px;
      }
      .center-info {
        display: flex;
        width: 100%;
        .banner-left {
          width: 300px;
          height: auto;
          .swiper-box {
            width: 300px;
            min-height: 300px;
            .list-left-image {
              width: 260px;
              height: 260px;
            }
            .list-image-thumbs {
              margin-top: 8px;
              width: 93px;
              height: 93px;
            }
          }
          .banner-image {
            width: 300px;
            height: 300px;
          }
        }
        .food-money {
          margin-top: 20px;
          display: flex;
          align-items: center;
          .cost-text {
            color: #000;
          }
        }
        .banner-content {
          flex: 1;
          margin-left: 16px;
          font-size: 14px;
        }
      }
    }
  }
}
.mt-20 {
  margin-top: 16px;
}
.menu-box1 {
  width: 100%;
  height: 50px;
  background-color: rgba(245, 242, 245, 0.5);
  margin-top: -1px;
  overflow: hidden;
  border: 0px;
}
.menu-list {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  .menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin: 0 20px;
    font-size: 18px;
    font-weight: 600;
    color: #2d333f;
  }
  .menu-active {
    color: #e7b530;
    border-bottom: 3px solid #e7b530;
  }
}
.menu-box2 {
  position: fixed;
  top: 60px;
  left: 0;
  height: 50px;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #dcdfe6;
  border-top: 1px solid #dcdfe6;
  z-index: 1000;
}
.content-box {
  display: flex;
  width: 100%;
  height: auto;
  // background-color: rgba(245,242,245, 0.5);
  border-top: 1px solid #dcdfe6;
  .left-box {
    flex: 0.97;
    margin-top: 20px;
    margin-right: 10px;
    padding: 0px 20px 0;
    background-color: #fff;
    border: 1px solid #dcdfe6;
    border-radius: 5px;
    box-sizing: border-box;
    .store-info {
      .store-title {
        margin: 30px 0 40px;
        border-left: 3px solid #e7b530;
        padding-left: 20px;
        font-size: 18px;
        font-weight: 600;
      }
    }
    .social-media {
      .social-title {
        margin: 30px 0 40px;
        border-left: 3px solid #e7b530;
        padding-left: 20px;
        font-size: 18px;
        font-weight: 600;
      }
      .social-content {
        width: 100%;
        min-height: 100px;
        .social-list {
          display: flex;
          justify-content: flex-start;
          .social-item {
            margin: 10px 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            cursor: pointer;
            .social-logo {
              margin-bottom: 10px;
              width: 40px;
            }
            .social-text {
              font-size: 12px;
            }
            &:hover {
              .social-text {
                color: #e7b530;
              }
            }
          }
        }
      }
    }
    .infection-info {
      .infection-title {
        margin: 30px 0 40px;
        border-left: 3px solid #e7b530;
        padding-left: 20px;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
  .right-box {
    width: 360px;
    .right-fixed-box {
      width: 360px;
      min-height: 700px;
      background: #fff;
      z-index: -1;
    }
    .right-content-box {
      background: #fff;
      width: 100%;
      min-height: 700px;
    }
  }
}
.right-aside {
  background: #fff;
  margin-top: 20px;
  padding: 10px;
  width: 100%;
  min-height: 600px;
  -moz-box-shadow: 1px 1px 5px #e0e3e2;
  -webkit-box-shadow: 1px 1px 5px #e0e3e2;
  box-shadow: 1px 1px 5px #e0e3e2;
  .aside-map {
    width: 100%;
    height: 200px;
  }
  .aside-title {
    margin: 10px 0;
    font-size: 16px;
    font-weight: 600;
    color: #2d333f;
  }
  .aside-payType {
    line-height: 20px;
    font-size: 14px;
  }
  .aside-phone {
    font-size: 16px;
    color: #e7b530;
  }
}
.banner-content-title {
  font-size: 24px;
  font-weight: 400;
}
.store-service {
  margin-top: 22px;
  font-size: 16px;
  line-height: 22px;
}
.list-service {
  margin-top: 20px;
  height: 20px;
  line-height: 20px;
  display: flex;
  align-items: center;
  .service-item {
    display: flex;
    align-items: center;
    height: 20px;
    line-height: 20px;
    .service-logo {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
    .service-content {
      font-size: 12px;
    }
  }
}
.store-rate {
  margin: 10px 0 20px;
  ::v-deep .el-rate__icon {
    font-size: 32px;
  }
  ::v-deep .el-rate__text {
    font-size: 28px;
    font-weight: 800;
  }
}
.aside-address {
  padding-bottom: 8px;
  .address-text {
    line-height: 24px;
    white-space: pre-wrap;
  }
}
.aside-week {
  margin-bottom: 12px;
}
.aside-time {
  margin-bottom: 12px;
}
.not-image {
  width: 300px;
  height: 300px;
  background: rgb(244, 244, 244);
}
.infection-content li {
  margin-left: 18px;
  list-style: initial;
  line-height: 30px;
}
.not-content {
  color: #c0c4cc;
}
.couponBox {
  min-width: 106px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  color: #fff;
  box-sizing: border-box;
  background: radial-gradient(transparent 0, transparent 4px, #ffb937 4px);
  background-size: 15px 14px;
  background-position: 8px 0px;
  .coupon-warper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #ffb937;
    line-height: 18px;
    width: 100%;
    height: 100%;
  }
}
.couponValue {
  text-align: center;
  margin-top: 6px;
  font-weight: 800;
  font-size: 20px;
  color: #fff;
}
.go-to-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #dcdfe6;
  border-radius: 5px;
  background: #409eff;
  width: 100%;
  height: 40px;
  font-size: 16px;
  color: #fff;
}
.content-warper {
  width: 100%;
  height: auto;
  margin-top: -10px;
  padding-bottom: 20px;
  background-color: rgba(245, 242, 245, 0.5);
}
.video-img-list {
  margin-top: 10px;
}
.video-select {
  border: 1px solid #ffb937;
  box-sizing: border-box;
}
</style>
