<template>
  <div class="left-box">
    <div
      v-if="detailData.videoList && detailData.videoList.length > 0"
      class="store-info mt-20"
      ref="StoreVideo"
    >
      <iframe
        width="100%"
        height="440"
        :src="getYoutubeUrl(videoPlay)"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <!-- <el-button v-for="(item, index) in detailData.videoList" :key="index" plain style="margin-right: 10px" @click="() => { videoPlay = item }">{{ $t('视频') }}{{ index }}</el-button> -->
      <div class="video-img-list">
        <!-- <div class="video-left-btn"><></div> -->
        <el-image
          v-for="(item, index) in detailData.videoList"
          :key="index"
          fit="contain"
          :src="`http://i.ytimg.com/vi/${item}/default.jpg`"
          :class="videoPlay == item ? 'video-select' : ''"
          style="width: 120px;height:90px;cursor:pointer; margin-right: 10px"
          @click="
            () => {
              videoPlay = item;
            }
          "
        />
      </div>
    </div>
    <div class="store-info" ref="StoreInfo">
      <div class="store-title">{{ $t("景点总览") }}</div>
      <!-- 插入html -->
      <div
        v-if="detailData.storeDescription"
        v-html="detailData.storeDescription.replace(/\n/g, '<br/>')"
        style="line-height:1.5"
      />
      <div v-else class="not-content">{{ $t("暂无内容") }}</div>
      <el-divider></el-divider>
    </div>
    <div class="social-media" ref="Social">
      <div v-if="detailData.treeId === 1" class="social-title">
        {{ $t("餐厅SNS") }}
      </div>
      <div v-else class="social-title">{{ $t("购物") }} SNS</div>
      <!-- 餐厅SNS内容 -->
      <div
        v-if="
          !detailData.facebook &&
            !detailData.youtube &&
            !detailData.instagram &&
            !detailData.ticTok &&
            !detailData.twitterUrl
        "
        class="not-content"
      >
        {{ $t("暂无内容") }}
      </div>
      <div v-else class="social-content">
        <div class="social-list">
          <el-link
            v-if="detailData.facebook"
            :href="detailData.facebook"
            :underline="false"
            target="_blank"
          >
            <div class="social-item">
              <el-image :src="facebook" class="social-logo" lazy></el-image>
              <span class="social-text">Facebook</span>
            </div>
          </el-link>
          <el-link
            v-if="detailData.twitterUrl"
            :href="detailData.twitterUrl"
            :underline="false"
            target="_blank"
          >
            <div class="social-item">
              <el-image :src="twitter" class="social-logo" lazy></el-image>
              <span class="social-text">Twitter</span>
            </div>
          </el-link>
          <el-link
            v-if="detailData.youtube"
            :href="detailData.youtube"
            :underline="false"
            target="_blank"
          >
            <div class="social-item">
              <el-image :src="youtube" class="social-logo" lazy></el-image>
              <span class="social-text">YouTube</span>
            </div>
          </el-link>
          <el-link
            v-if="detailData.instagram"
            :href="detailData.instagram"
            :underline="false"
            target="_blank"
          >
            <div class="social-item">
              <el-image :src="ins" class="social-logo" lazy></el-image>
              <span class="social-text">Instagram</span>
            </div>
          </el-link>
          <el-link
            v-if="detailData.ticTok"
            :href="detailData.ticTok"
            :underline="false"
            target="_blank"
          >
            <div v-if="detailData.ticTok" class="social-item">
              <el-image :src="tictok" class="social-logo" lazy></el-image>
              <span class="social-text">TikTok</span>
            </div>
          </el-link>
        </div>
      </div>
    </div>
    <div class="infection-info" ref="Infection">
      <div class="infection-title">{{ $t("防疫对策") }}</div>
      <ul class="infection-content">
        <li v-for="item in detailData.measures" :key="item">
          {{ measuresName(item) }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { measuresList } from "@/utils/public_data";
export default {
  data() {
    return {
      detailData: {},
      videoShow: false,
      facebook: require("@/assets/images/media-01.png"),
      twitter: require("@/assets/images/media-05.png"),
      youtube: require("@/assets/images/media-02.png"),
      ins: require("@/assets/images/media-03.png"),
      tictok: require("@/assets/images/media-04.png"),
      videoPlay: ""
    };
  },
  methods: {
    scrollGoto(el, block) {
      console.log(el);
      console.log(block);
      this.$refs[el].scrollIntoView({ block });
    },
    init(data) {
      this.detailData = data;
      if (this.detailData) {
        if (this.detailData.videoUrl) {
          this.videoShow = false;
          this.$set(
            this.detailData,
            "videoList",
            this.detailData.videoUrl.split(",")
          );
          if (
            this.detailData.videoList &&
            this.detailData.videoList.length > 0
          ) {
            this.videoPlay = this.detailData.videoList[0];
            this.videoShow = true;
          }
        }
      }
    },
    getYoutubeUrl(videoId) {
      if (videoId.indexOf("http") === 0) {
        return videoId;
      }
      return "https://www.youtube.com/embed/" + videoId;
    },
    measuresName(measure) {
      var measures = measuresList();
      if (measures) {
        for (var i = 0; i < measures.length; i++) {
          if (measures[i].value === measure) {
            return measures[i].label;
          }
        }
      }
      return "";
    }
  }
};
</script>
<style lang="scss" scoped>
.banner-box {
  width: 100%;
  min-height: 450px;
  // background: url('../../assets/images/banner-02.png');
  // background: linear-gradient(to top left,#F7971E,#FFD200);
  .banner-wrapper {
    padding: 10px;
    background-color: rgba(245, 242, 245, 0.5);
    .top-crumb {
      margin-top: 10px;
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      font-size: 16px;
      ::v-deep .el-link {
        font-size: 16px;
        &:hover {
          color: #e7b530;
        }
      }
    }
    .banner-content-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      width: 100%;
      min-height: 400px;
      padding: 20px;
      box-sizing: border-box;
      background: #fff;
      border: 1px solid #dcdfe6;
      border-radius: 5px;
      .bottom-order {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        .detail-content {
          display: flex;
          justify-content: flex-start;
        }
      }
      .top-crumb span {
        padding: 0 6px;
      }
      .center-info {
        display: flex;
        width: 100%;
        .banner-left {
          width: 300px;
          height: auto;
          .swiper-box {
            width: 300px;
            min-height: 300px;
            .list-left-image {
              width: 260px;
              height: 260px;
            }
            .list-image-thumbs {
              margin-top: 8px;
              width: 93px;
              height: 93px;
            }
          }
          .banner-image {
            width: 300px;
            height: 300px;
          }
        }
        .food-money {
          margin-top: 20px;
          display: flex;
          align-items: center;
          .cost-text {
            color: #000;
          }
        }
        .banner-content {
          flex: 1;
          margin-left: 16px;
          font-size: 14px;
        }
      }
    }
  }
}
.mt-20 {
  margin-top: 16px;
}
.menu-box1 {
  width: 100%;
  height: 50px;
  background-color: rgba(245, 242, 245, 0.5);
  margin-top: -1px;
  overflow: hidden;
  border: 0px;
}
.menu-list {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  .menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin: 0 20px;
    font-size: 18px;
    font-weight: 600;
    color: #2d333f;
  }
  .menu-active {
    color: #e7b530;
    border-bottom: 3px solid #e7b530;
  }
}
.menu-box2 {
  position: fixed;
  top: 60px;
  left: 0;
  height: 50px;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #dcdfe6;
  border-top: 1px solid #dcdfe6;
  z-index: 10000;
}
.content-box {
  display: flex;
  width: 100%;
  height: auto;
  // background-color: rgba(245,242,245, 0.5);
  border-top: 1px solid #dcdfe6;
  .left-box {
    flex: 0.97;
    margin-top: 20px;
    margin-right: 10px;
    padding: 0px 20px 0;
    background-color: #fff;
    border: 1px solid #dcdfe6;
    border-radius: 5px;
    box-sizing: border-box;
    .store-info {
      .store-title {
        margin: 30px 0 40px;
        border-left: 3px solid #e7b530;
        padding-left: 20px;
        font-size: 18px;
        font-weight: 600;
      }
    }
    .social-media {
      .social-title {
        margin: 30px 0 40px;
        border-left: 3px solid #e7b530;
        padding-left: 20px;
        font-size: 18px;
        font-weight: 600;
      }
      .social-content {
        width: 100%;
        min-height: 100px;
        .social-list {
          display: flex;
          justify-content: flex-start;
          .social-item {
            margin: 10px 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            cursor: pointer;
            .social-logo {
              margin-bottom: 10px;
              width: 40px;
            }
            .social-text {
              font-size: 12px;
            }
            &:hover {
              .social-text {
                color: #e7b530;
              }
            }
          }
        }
      }
    }
    .infection-info {
      .infection-title {
        margin: 30px 0 40px;
        border-left: 3px solid #e7b530;
        padding-left: 20px;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
  .right-box {
    width: 360px;
    .right-fixed-box {
      width: 360px;
      min-height: 700px;
      background: #fff;
      z-index: -1;
    }
    .right-content-box {
      background: #fff;
      width: 100%;
      min-height: 700px;
    }
  }
}
.right-aside {
  background: #fff;
  margin-top: 20px;
  padding: 10px;
  width: 100%;
  min-height: 600px;
  -moz-box-shadow: 1px 1px 5px #e0e3e2;
  -webkit-box-shadow: 1px 1px 5px #e0e3e2;
  box-shadow: 1px 1px 5px #e0e3e2;
  .aside-map {
    width: 100%;
    height: 200px;
  }
  .aside-title {
    margin: 10px 0;
    font-size: 16px;
    font-weight: 600;
    color: #2d333f;
  }
  .aside-payType {
    line-height: 20px;
    font-size: 14px;
  }
  .aside-phone {
    font-size: 16px;
    color: #e7b530;
  }
}
.banner-content-title {
  font-size: 24px;
  font-weight: 400;
}
.store-service {
  margin-top: 22px;
  font-size: 16px;
  line-height: 22px;
}
.list-service {
  margin-top: 20px;
  height: 20px;
  line-height: 20px;
  display: flex;
  align-items: center;
  .service-item {
    display: flex;
    align-items: center;
    height: 20px;
    line-height: 20px;
    .service-logo {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
    .service-content {
      font-size: 12px;
    }
  }
}
.store-rate {
  margin: 10px 0 20px;
  ::v-deep .el-rate__icon {
    font-size: 32px;
  }
  ::v-deep .el-rate__text {
    font-size: 28px;
    font-weight: 800;
  }
}
.aside-address {
  padding-bottom: 8px;
  .address-text {
    line-height: 24px;
    white-space: pre-wrap;
  }
}
.aside-week {
  margin-bottom: 12px;
}
.aside-time {
  margin-bottom: 12px;
}
.not-image {
  width: 300px;
  height: 300px;
  background: rgb(244, 244, 244);
}
.infection-content li {
  margin-left: 18px;
  list-style: initial;
  line-height: 30px;
}
.not-content {
  color: #c0c4cc;
}
.couponBox {
  min-width: 106px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  color: #fff;
  box-sizing: border-box;
  background: radial-gradient(transparent 0, transparent 4px, #ffb937 4px);
  background-size: 15px 14px;
  background-position: 8px 0px;
  .coupon-warper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #ffb937;
    line-height: 18px;
    width: 100%;
    height: 100%;
  }
}
.couponValue {
  text-align: center;
  margin-top: 6px;
  font-weight: 800;
  font-size: 20px;
  color: #fff;
}
.go-to-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #dcdfe6;
  border-radius: 5px;
  background: #409eff;
  width: 100%;
  height: 40px;
  font-size: 16px;
  color: #fff;
}
.content-warper {
  width: 100%;
  height: auto;
  margin-top: -10px;
  padding-bottom: 20px;
  background-color: rgba(245, 242, 245, 0.5);
}
.video-img-list {
  margin-top: 10px;
}
.video-select {
  border: 1px solid #ffb937;
  box-sizing: border-box;
}
</style>
