<template>
  <div class="all-container">
    <div class="line-box"></div>
    <div class="menu-box1">
      <div class="container-mb">
        <div class="menu-list">
          <div v-for="item in menus" :key="item.value" class="menu-item" :class="{'menu-active': active === item.value }" @click="changeMenu(item.value)">{{ item.label }}</div>
        </div>
      </div>
    </div>
    <div class="container-mb">
      <div v-if="detailData.videoList && detailData.videoList.length > 0" class="store-info" ref="StoreVideo">
        <iframe width="100%" height="220" :src="getYoutubeUrl(videoPlay)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <!-- <el-button v-for="(item, index) in detailData.videoList" :key="index" plain style="margin-right: 10px" @click="() => { videoPlay = item }">{{ $t('视频') }}{{ index }}</el-button> -->
        <div class="video-img-list">
          <!-- <div class="video-left-btn"><></div> -->
          <el-image v-for="(item, index) in detailData.videoList" :key="index" :src="`http://i.ytimg.com/vi/${item}/default.jpg`" :class="videoPlay == item?'video-select': ''" style="width: 60px;height:45px;cursor:pointer; margin-right: 10px" @click="() => { videoPlay = item }" />
        </div>
      </div>
      <div class="store-info" ref="StoreInfo">
        <div class="store-title">{{ $t('景点总览') }}</div>
        <!-- 插入html -->
        <div v-if="detailData.storeDescription" v-html="detailData.storeDescription" class="detail-content" style="line-height:1.5" />
        <div v-else class="not-content">{{ $t('暂无内容') }}</div>
        <el-divider></el-divider>
      </div>
      <div class="social-media" ref="Social">
        <div class="social-title">{{ $t('餐厅SNS') }}</div>
        <!-- 餐厅SNS内容 -->
        <div v-if="!detailData.facebook && !detailData.youtube && !detailData.instagram && !detailData.ticTok && !detailData.twitterUrl" class="not-content">
          {{ $t('暂无内容') }}
        </div>
        <div v-else class="social-content">
          <div class="social-list">
            <el-link v-if="detailData.facebook" :href="detailData.facebook" :underline="false" target="_blank">
              <div class="social-item">
                <el-image :src="facebook" class="social-logo" lazy></el-image>
                <span class="social-text">Facebook</span>
              </div>
            </el-link>
            <el-link v-if="detailData.twitterUrl" :href="detailData.twitterUrl" :underline="false" target="_blank">
              <div class="social-item">
                <el-image :src="twitter" class="social-logo" lazy></el-image>
                <span class="social-text">Twitter</span>
              </div>
            </el-link>
            <el-link v-if="detailData.youtube" :href="detailData.youtube" :underline="false" target="_blank">
              <div class="social-item">
                <el-image :src="youtube" class="social-logo" lazy></el-image>
                <span class="social-text">YouTube</span>
              </div>
            </el-link>
            <el-link v-if="detailData.instagram" :href="detailData.instagram" :underline="false" target="_blank">
              <div class="social-item">
                <el-image :src="ins" class="social-logo" lazy></el-image>
                <span class="social-text">Instagram</span>
              </div>
            </el-link>
            <el-link v-if="detailData.ticTok" :href="detailData.ticTok" :underline="false" target="_blank">
              <div v-if="detailData.ticTok" class="social-item">
                <el-image :src="tictok" class="social-logo" lazy></el-image>
                <span class="social-text">TikTok</span>
              </div>
            </el-link>
          </div>
        </div>
      </div>
      <div class="infection-info" ref="Infection">
        <div class="infection-title">{{ $t('防疫对策') }}</div>
        <ul class="infection-content">
          <li v-for="item in detailData.measures" :key="item" class="lh-16">
            {{ measuresName(item) }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { formatMoney } from '@/utils/money'
import { lanMap, dealWeekDay } from '@/utils/util'
// import { getLanguage } from '@/lang/index'
import { weeks, storeShopType, storeService2, storePayType, measuresList, onlinePayType, qrPayType } from '@/utils/public_data'
import i18n from '@/lang/index'
import Swiper from 'swiper'

export default {
  filters: {
    moneyFilter(val) {
      return formatMoney(val)
    },
    timeFilter(val) {
      return val.substring(0,5)
    }
  },
  data() {
    return {
      storeId: 0,
      menus: [
        { value: 1, label: '景点总览' },
        { value: 2, label: '餐厅SNS' },
        { value: 3, label: '防疫对策' }
      ],
      detailData: {},
      // center: { lng: 139.764887, lat: 35.682084 },
      zooms: 15,
      active: 1,
      showFlag: false,
      weeks: weeks(),
      shopType: storeShopType(),
      service: storeService2(),
      payType: storePayType(),
      onlineType: onlinePayType(),
      qrType: qrPayType(),
      facebook: require('@/assets/images/media-01.png'),
      youtube: require('@/assets/images/media-02.png'),
      twitter: require('@/assets/images/media-05.png'),
      ins: require('@/assets/images/media-03.png'),
      tictok: require('@/assets/images/media-04.png'),
      dataLoading: false,
      BMap: null,
      map: null,
      mapShow: false,
      thumbsSwiper: {},
      swiper: {},
      zoom: 14,
      url: 'http://www.google.jp/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}',
      attribution: '&copy; <a href="http://www.google.jp">Google</a>',
      currentZoom: 11.5,
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5
      },
      tms: false,
      subdomains: '',
      tileLayerOptions: {
        maxZoom: 18,
        minZoom: 8
      },
      marker: [],
      iconUrl: require('@/assets/images/address.png'),
      showDetail: false,
      showMap: true,
      videoShow: false,
      videoPlay: null
    }
  },
  computed: {
    lan() {
      return i18n.locale
    }
  },
  watch: {
    center: {
      handler(newVal) {
        console.log(newVal)
      },
      deep: true
    },
    lan(newVal) {
      lanMap(this, newVal)
    }
  },
  methods: {
    handle(val) {
      this.$router.push(val)
    },
    dealWeekDays(list) {
      // console.log(dealWeekDay(list))
      return dealWeekDay(list)
    },
    zoomUpdated (zoom) {
      this.zoom = zoom;
    },
    centerUpdated (center) {
      this.center = center;
    },
    boundsUpdated (bounds) {
      this.bounds = bounds;
    },
    initSwiper() {
      this.swiper = new Swiper(`.img-top`, {
        spaceBetween: 10,
        nextButton: `.swiper-button-next`,
        prevButton: `.swiper-button-prev`,
        speed: 1000,
        loop: false,
        pagination: {
          el: '.swiper-pagination',
        },
      })
    },
    init(data){
      this.dataLoading = true
      this.mapShow = false
      if(data) {
        this.detailData = data
        if(this.detailData.treeId === 1) {
          this.menus = [ { value: 1, label: this.$t('景点总览') }, { value: 2, label: this.$t('餐厅SNS') }, { value: 3, label: this.$t('防疫对策') } ]
        } else {
          this.menus = [ { value: 1, label: this.$t('景点总览') }, { value: 2, label: 'SNS' }, { value: 3, label: this.$t('防疫对策') } ]
        }
        let minPrice = this.detailData.breakfast
        let maxPrice = this.detailData.lunch
        if (minPrice==0 || minPrice > this.detailData.dinner) {
          minPrice = this.detailData.dinner
        }
        if (minPrice==0 || minPrice > this.detailData.lunch) {
          minPrice = this.detailData.lunch
        }
        if (maxPrice==0 || maxPrice < this.detailData.dinner) {
          maxPrice = this.detailData.dinner
        }
        if (maxPrice==0 || maxPrice < this.detailData.breakfast) {
          maxPrice = this.detailData.breakfast
        }
        this.$set(this.detailData, 'minPrice', minPrice)
        this.$set(this.detailData, 'maxPrice', maxPrice)
        if (this.detailData.storeImg) {
          this.$set(this.detailData, 'imgList', this.detailData.storeImg.split(','))
        }
        console.log(this.detailData.imgList)
        if (this.detailData.imgList && this.detailData.imgList.length > 0) {
          this.$nextTick(() => {
            this.initSwiper()
          })
        }
        if (this.detailData.storeType) {
          this.$set(this.detailData, 'storeTypes', this.detailData.storeType.split(','))
        }
        if(this.detailData.stipulationRestDays) {
          // eslint-disable-next-line no-useless-escape
          var stipulationRestDaysArr = this.detailData.stipulationRestDays.split('\/\/')
          this.$set(this.detailData, 'restDay', stipulationRestDaysArr[0].replace('undefined', '').replace(/^,+/,"").split(',').concat(stipulationRestDaysArr[1]))
        }
        if (this.detailData.allowPayType) {
          this.$set(this.detailData, 'payTypes', this.detailData.allowPayType.split(','))
        }
        if (this.detailData.allowOnlinePay) {
          this.$set(this.detailData, 'onlineTypes', this.detailData.allowOnlinePay.split(','))
        }
        if (this.detailData.allowQrPay) {
          this.$set(this.detailData, 'qrTypes', this.detailData.allowQrPay.split(','))
        }
        if (this.detailData.storeDescribe) {
          this.$set(this.detailData, 'measures', this.detailData.storeDescribe.split(','))
        }
        if (this.detailData.storeBusinessTimeDtoList && this.detailData.storeBusinessTimeDtoList.length > 0) {
          this.detailData.storeBusinessTimeDtoList.map(item => {
            this.$set(item, 'weeks', item.week.split(',').sort())
          })
          let startTime = this.detailData.storeBusinessTimeDtoList[0].startTime
          let endTime = this.detailData.storeBusinessTimeDtoList[0].endTime
          if(startTime && endTime) {
            this.$set(this.detailData, 'businessTimes', startTime + "~" + endTime)
          }
        }
        if (this.detailData.latitude && this.detailData.longitude) {
          this.mapShow = true
        }
        if (this.detailData.videoUrl) {
          this.videoShow = false
          this.$set(this.detailData, 'videoList', this.detailData.videoUrl.split(','))
          if (this.detailData.videoList && this.detailData.videoList.length > 0) {
            this.menus.unshift({ value: 4, label: this.$t('景点视频') })
            this.active = 4
            this.videoPlay = this.detailData.videoList[0]
            this.videoShow = true
          }
        }
        if (this.detailData.storeDescription) {
          let result = this.detailData.storeDescription
          const regex = new RegExp('<img', 'gi')
          result = result.replace(regex, `<img style="width: 100%;max-width: 100%;height: auto;display:block;margin-left:0;"`)
          result = result.replace(/\n/g,'<br/>')
          this.detailData.storeDescription = result
        }
        this.dataLoading = false
      }
    },
    changeMenu(value) {
      this.active = value
      // 这里可以进行页面的跳动
      // this.$refs.FirstStep.scrollIntoView({block: "start"})
      if (this.active === 1) {
        this.$refs.StoreInfo.scrollIntoView({block: "center"})
      }
      if (this.active === 2) {
        this.$refs.Social.scrollIntoView({block: "center"})
      }
      if (this.active === 3) {
        this.$refs.Infection.scrollIntoView({block: "center"})
      }
    },
    measuresName(measure) {
      var measures = measuresList()
      for (var i = 0; i < measures.length; i++){
        if(measures[i].value === measure){
          return measures[i].label
        }
      }
      return ''
    },
    getRestDay(restItem) {
      if (this.weeks[restItem]) {
        return this.weeks[restItem]
      } else {
        return restItem
      }
    },
    getYoutubeUrl(videoId) {
      if(videoId.indexOf("http") === 0) {
        return videoId
      }
      return 'https://www.youtube.com/embed/' + videoId
    },
    // 控制menu的出现或消失
    showTop() {
      if (!!document.documentElement.scrollTop && document.documentElement.scrollTop > 300 ) {
          this.showFlag = true
        } else {
          this.showFlag = false
        }
    },
    // 获取地址label样式，英文环境处理
    getAddressLabelStyle() {
      if (this.$i18n.locale === 'en') {
        return 'width: 76px;'
      }
      return 'width: 50px;'
    },
    // 禁烟类型
    getSmokingType(smokingType) {
      var s = ''
      if (!smokingType) {
        return s
      }
      if (smokingType.toString().indexOf('1') > -1) {
        s += this.$t('禁止吸烟')
      }
      if (smokingType.toString().indexOf('2') > -1) {
        if (s.length > 0) {
          s += ','
        }
        s += this.$t('店内可吸烟')
      }
      if (smokingType.toString().indexOf('3') > -1){
        s += '(' + this.$t('有吸烟室') + ')'
      }
      if (smokingType.toString().indexOf('4') > -1) {
        if (s.length > 0) {
          s += ','
        }
        s += this.$t('禁止吸烟')
      }
      return s
    }
  }
}
</script>
<style lang='scss' scoped>
.line-box {
  width: 100%;
  height: 20px;
  background: rgb(245,242,245);
}
.top-crumb {
  border-top: 1px solid #DCDFE6;
  padding: 6px 0px 10px 10px;
}
.img-box {
  width: 100%;
  height: 200px;
  .img-top {
    width: 100%;
    height: 200px;
    .list-image {
      width: 100%;
    }
  }
}
.contain {
  padding-top: 30px;
  width: 100%;
  min-height: 100px;
  box-sizing: border-box;
  .content-title {
    font-size: 18px;
    font-weight: 600;
  }
  .content-rate {
    margin: 8px 0 10px;
    ::v-deep .el-rate__icon {
      font-size: 24px;
    }
    ::v-deep .el-rate__text {
      font-size: 18px;
    }
  }
  .content-time {
    display: flex;
    flex-wrap: wrap;
  }
  .content-money {
    width: 100%;
    .cost-text {
      color:  #F56C6C;
    }
    // display: flex;
    // flex-wrap: wrap;
  }
  .content-discount {
    width: 100%;
    background: #FDF6EB;
    .cost-discount {
      padding: 4px;
      line-height: 16px;
      .const-red-content {
        color:  #F56C6C;
      }
    }
  }
  .content-map {
    width: 100%;
    height: 200px;
  }
}
.menu-box1 {
  margin-top: 30px;
  width: 100%;
  height: 50px;
  border-bottom:  1px solid #DCDFE6;
}
.menu-list {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  .menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin: 0 10px;
    font-size: 14px;
    font-weight: 600;
    color: #2D333F;
  }
  .menu-active {
    color: #E7B530;
    border-bottom: 3px solid #E7B530;
  }
}
.store-info {
  width: 100%;
  .store-title {
    width: 100%;
    margin: 30px 0 40px;
    border-left: 3px solid #E7B530;
    padding-left: 20px;
    font-size: 18px;
    font-weight: 600;
  }
  .detail-content {
    width: 100%;
    img {
      width: 100%;
      height: 200px;
    }
  }
}
.social-media {
  width: 100%;
  .social-title {
    margin: 30px 0 40px;
    border-left: 3px solid #E7B530;
    padding-left: 20px;
    font-size: 18px;
    font-weight: 600;
  }
  .social-content {
    width: 100%;
    min-height: 100px;
    .social-list {
      display: flex;
      justify-content: flex-start;
      .social-item {
        margin: 10px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor:pointer;
        .social-logo {
          margin-bottom: 10px;
          width: 40px;
        }
        .social-text {
          font-size: 12px;
        }
        &:hover {
          .social-text {
            color: #E7B530;
          }
        }
      }
    }
  }
}
.infection-info {
  width: 100%;
  .infection-title {
    margin: 30px 0 40px;
    border-left: 3px solid #E7B530;
    padding-left: 20px;
    font-size: 18px;
    font-weight: 600;
  }
}
.mt-20 {
  margin-top: 20px;
}
.mt-10 {
  margin-top: 10px;
}
.mr-20 {
  margin-right: 20px;
}
.flex-box {
  display: flex;
  flex-wrap: wrap;
  line-height: 16px;
}
.mb-10 {
  margin-bottom: 10px;
}
.title-text {
  font-weight: 600;
}
.lh-16 {
  line-height: 16px;
}
.go-to-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  border: 1px solid #000;
  background:rgb(70, 116, 193);
  width: 100%;
  height: 40px;
  font-size: 16px;
}
.video-img-list {
  margin-top: 6px;
}
.video-select {
  border: 1px solid #ffb937;
  box-sizing: border-box;
}
</style>
