<template>
  <div class="right-box">
    <!-- 在原来位置，不会随着一起滚动的 -->
    <div class="right-content-box">
      <div class="right-aside">
        <div class="aside-title">{{ $t('店铺地址') }}</div>
        <div class="aside-address"><span v-if="detailData.address" class="address-text">{{ detailData.address }}</span><span v-else>-</span></div>
        <l-map v-if="mapShow" class="aside-map" :zoom="zoom" :center="center" :crs="crs" @update:zoom="zoomUpdated" @update:center="centerUpdated" @update:bounds="boundsUpdated">
          <l-tile-layer :url="url" :subdomains="subdomains" :options="tileLayerOptions" :tms="tms"></l-tile-layer>
          <l-marker :lat-lng="marker" :icon="addressIcon" :attribution="attribution">
            <!-- <l-icon :icon-anchor="[32, 32]" :icon-size="[32, 32]" :icon-url="iconUrl"></l-icon> -->
            <l-popup>{{ detailData.storeName }}</l-popup>
          </l-marker>
        </l-map>
        <el-divider></el-divider>
        <div class="aside-title">{{ $t('交通路线') }}</div>
        <div><span v-if="detailData.trafficRoute">{{ detailData.trafficRoute}}</span><span v-else>-</span></div>
        <el-divider></el-divider>
        <div class="aside-title">{{ $t('电话番号') }}</div>
        <div><span v-if="detailData.phone">{{ detailData.phone}}</span><span v-else>-</span></div>
        <el-divider></el-divider>
        <div class="aside-title">{{ $t('营业时间') }}</div>
        <div v-if="detailData.treeId === 1" class="aside-Announcement">
          <span style="color:#ffbb2c;" v-for="(item,i) in Announcement1" :key="i">※{{item[lang]}}</span>
        </div>
        <div v-if="detailData.treeId === 2" class="aside-Announcement">
          <span style="color:#ffbb2c;" v-for="(item,i) in Announcement2" :key="i">※{{item[lang]}}</span>
        </div>
        <div v-if="detailData.storeBusinessTimeDtoList && detailData.storeBusinessTimeDtoList.length > 0">
          <div v-for="timeItem in detailData.storeBusinessTimeDtoList" :key="timeItem.id">
            <div v-if="timeItem.weeks && timeItem.weeks.length === 1" class="aside-week">
              <span>{{ weeks[`${timeItem.weeks[0]}`] }}</span>
            </div>
            <div v-else class="aside-week">
              <span v-for="(weekItem, weekIndex) in dealWeekDays(timeItem.week)" :key="weekItem">
                <!-- {{ weeks[weekItem] }} -->
                <span v-if="weeks[weekItem].indexOf('~') > -1">{{ $t(weeks[weekItem].split('~')[0]) }}~{{ $t(weeks[weekItem].split('~')[1]) }}</span>
                <span v-else>{{ $t(weeks[weekItem]) }}</span>
                <span v-if="weekIndex !== dealWeekDays(timeItem.week).length - 1">、</span>
              </span>
            </div>
            <div class="aside-time">
              <span>{{ timeItem.startTime | timeFilter }}</span>
              <span>-</span>
              <span>{{ timeItem.endTime | timeFilter }}</span>
            </div>
          </div>
        </div>
        <div v-else>-</div>
        <el-divider></el-divider>
        <div class="aside-title">{{ $t('公休日') }}</div>
        <div v-if="detailData.stipulationRestDays && !detailData.stipulationRestDays.startsWith('8') && !detailData.stipulationRestDays.startsWith('9')">
          <span v-for="(restItem, restIndex) in detailData.restDay" :key="restItem">{{ $t(getRestDay(restItem)) }}<span v-if="detailData.restDay && restIndex < detailData.restDay.length - 1" style="margin-right: 10px;">,</span></span>
        </div>
        <div v-else style="line-height: 28px">
          <span v-if="detailData.stipulationRestDays && detailData.stipulationRestDays.startsWith('8')">{{ $t('不定期休息') }}</span>
          <span v-if="detailData.stipulationRestDays && detailData.stipulationRestDays.startsWith('9')">{{ $t('无休') }}</span>
          <div v-if="detailData.restDay && detailData.restDay.length > 0">{{ detailData.restDay[1] }}</div>
        </div>
        <el-divider></el-divider>
        <div v-if="detailData.payTypes && detailData.payTypes.length > 0" class="aside-title">{{ $t('付款方式') }}</div>
        <div v-if="detailData.payTypes && detailData.payTypes.length > 0">
          <span v-for="(payTypeItem, payTypeIndex) in detailData.payTypes" :key="payTypeIndex" class="aside-payType">
            {{ $t(payType[payTypeItem]) }}
            <span v-if="payTypeItem === '8'">
              (
              <span v-for="(onlineItem, onlineIndex) in detailData.onlineTypes" :key="onlineIndex">{{ $t(onlineType[onlineItem]) }}
                <span v-if="onlineIndex < detailData.onlineTypes.length - 1">,</span>
              </span>
              )
            </span>
            <span v-if="payTypeItem === '9'">
              (
              <span v-for="(qrItem, qrIndex) in detailData.qrTypes" :key="qrIndex">{{ $t(qrType[qrItem]) }}
                <span v-if="qrIndex < detailData.qrTypes.length - 1">,</span>
              </span>
              )
            </span>
            <span v-if="payTypeIndex < detailData.payTypes.length - 1">,</span>
          </span>
        </div>
        <el-divider></el-divider>
        <div class="aside-title">{{ $t('禁烟类型') }}</div>
        <div>{{ getSmokingType(detailData.smokingType) }}</div>
        <el-divider v-if="detailData.payTypes && detailData.payTypes.length > 0"></el-divider>
        <div class="aside-title">{{ $t('店内施設') }}</div>
        <div style="line-height: 28px">
          <div v-if="detailData.treeId === 1 && detailData.boxNumber" class="mr-20">{{ $t('包厢容纳人数') }}：{{ detailData.boxNumber }}</div>
          <!-- <div v-else class="mr-20">{{ $t('包厢容纳人数') }}：-</div> -->
          <div v-if="detailData.treeId === 1 && detailData.seatsNumber">{{ $t('席位数') }}：{{ detailData.seatsNumber }}</div>
          <!-- <div v-else>{{ $t('席位数') }}：-</div> -->
        </div>
        <div v-if="detailData.treeId === 1 && detailData.specialMeal && detailData.specialMeal != '' && detailData.specialMeal != '0'">
          <el-divider></el-divider>
          <div class="aside-title">{{ $t('供应') }}</div>
          <!-- <div v-if="detailData.specialMeal.indexOf('1') != -1" class="mt-20">{{ $t('提供宗教餐') }}</div> -->
          <div v-if="detailData.specialMeal.indexOf('2') != -1" class="mt-20">{{ $t('提供僧侣料理') }}</div>
          <div v-if="detailData.specialMeal.indexOf('4') != -1" class="mt-20">{{ $t('提供僧侣料理需提前预约') }})</div>
          <div v-if="detailData.specialMeal.indexOf('3') != -1" class="mt-20">{{ $t('提供清真料理') }}</div>
          <div v-if="detailData.specialMeal.indexOf('5') != -1" class="mt-20">{{ $t('提供清真料理需提前预约') }})</div>
        </div>
        <el-divider></el-divider>
        <div class="aside-title">{{ $t('官方网址') }}</div>
        <div><span v-if="detailData.officialWebsite">
            <el-link :href="detailData.officialWebsite" :underline="false" target="_blank">{{ detailData.officialWebsite }}</el-link>
          </span>
          <span v-else>-</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatMoney } from '@/utils/money'
import { lanMap, dealWeekDay } from '@/utils/util'
// import { getLanguage } from '@/lang/index'
import { weeks, storeShopType, storeService, storePayType, onlinePayType, qrPayType, getDefaultLon, getDefaultLat, Announcement1,Announcement2 } from '@/utils/public_data'
import { latLng, icon } from 'leaflet'
// LIcon,
import L from 'leaflet'
import 'proj4'
import 'proj4leaflet'
import 'leaflet.chinatmsproviders'
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet'
export default {
  components: { LMap, LTileLayer, LMarker, LPopup },
  filters: {
    moneyFilter(val) {
      return formatMoney(val)
    },
    timeFilter(val) {
      return val.substring(0, 5)
    },
  },
  data() {
    return {
      storeId: 0,
      menus: [
        { value: 1, label: '景点总览' },
        { value: 2, label: '餐厅SNS' },
        { value: 3, label: '防疫对策' },
      ],
      detailData: {},
      // center: { lng: 139.764887, lat: 35.682084 },
      zooms: 15,
      active: 1,
      showFlag: false,
      weeks: weeks(),
      shopType: storeShopType(),
      service: storeService(),
      payType: storePayType(),
      onlineType: onlinePayType(),
      qrType: qrPayType(),
      facebook: require('@/assets/images/media-01.png'),
      twitter: require('@/assets/images/media-05.png'),
      youtube: require('@/assets/images/media-02.png'),
      ins: require('@/assets/images/media-03.png'),
      tictok: require('@/assets/images/media-04.png'),
      dataLoading: false,
      BMap: null,
      map: null,
      thumbsSwiper: {},
      swiper: {},
      zoom: 14,
      mapShow: false,
      center: latLng(getDefaultLat(), getDefaultLon()),
      Announcement1: Announcement1(),
      Announcement2:Announcement2(),
      lang: '',
      url: 'http://www.google.jp/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}',
      attribution: '&copy; <a href="http://www.google.jp">Google</a>',
      subdomains: '',
      currentZoom: 11.5,
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
      },
      crs: L.CRS.EPSG3857,
      tms: false,
      tileLayerOptions: {
        maxZoom: 18,
        minZoom: 8,
      },
      marker: [],
      iconUrl: require('@/assets/images/address.png'),
      addressIcon: icon({
        iconUrl: require('@/assets/images/address.png'),
        iconSize: [32, 32],
        iconAnchor: [16, 10],
      }),
    }
  },
  watch: {
    center: {
      handler(newVal) {
        console.log(newVal)
      },
      deep: true,
    },
    lan(newVal) {
      lanMap(this, newVal)
    },
  },
  created() {
    lanMap(this, this.lan)
    this.lang = localStorage.getItem('locale')
    return localStorage.getItem('locale')
  },
  methods: {
    dealWeekDays(list) {
      // console.log(dealWeekDay(list))
      return dealWeekDay(list)
    },
    zoomUpdated(zoom) {
      this.zoom = zoom
    },
    centerUpdated(center) {
      this.center = center
    },
    boundsUpdated(bounds) {
      this.bounds = bounds
    },
    getRestDay(restItem) {
      if (this.weeks[restItem]) {
        return this.weeks[restItem]
      } else {
        return restItem
      }
    },
    init(data) {
      this.detailData = data
      if (this.detailData.storeBusinessTimeDtoList && this.detailData.storeBusinessTimeDtoList.length > 0) {
        this.detailData.storeBusinessTimeDtoList.map((item) => {
          this.$set(item, 'weeks', item.week.split(',').sort())
        })
        let startTime = this.detailData.storeBusinessTimeDtoList[0].startTime
        let endTime = this.detailData.storeBusinessTimeDtoList[0].endTime
        if (startTime && endTime) {
          this.$set(this.detailData, 'businessTimes', startTime + '~' + endTime)
        }
      }
      if (this.detailData.storeType) {
        this.$set(this.detailData, 'storeTypes', this.detailData.storeType.split(','))
      }
      if (this.detailData.stipulationRestDays) {
        // eslint-disable-next-line no-useless-escape
        var stipulationRestDaysArr = this.detailData.stipulationRestDays.split('//')
        this.$set(
          this.detailData,
          'restDay',
          stipulationRestDaysArr[0].replace('undefined', '').replace(/^,+/, '').split(',').concat(stipulationRestDaysArr[1])
        )
      }
      if (this.detailData.allowPayType) {
        this.$set(this.detailData, 'payTypes', this.detailData.allowPayType.split(','))
      }
      if (this.detailData.allowOnlinePay) {
        this.$set(this.detailData, 'onlineTypes', this.detailData.allowOnlinePay.split(','))
      }
      if (this.detailData.allowQrPay) {
        this.$set(this.detailData, 'qrTypes', this.detailData.allowQrPay.split(','))
      }
      if (this.detailData.storeDescribe) {
        this.$set(this.detailData, 'measures', this.detailData.storeDescribe.split(','))
      }
      if (this.detailData.storeBusinessTimeDtoList && this.detailData.storeBusinessTimeDtoList.length > 0) {
        this.detailData.storeBusinessTimeDtoList.map((item) => {
          this.$set(item, 'weeks', item.week.split(',').sort())
        })
        let startTime = this.detailData.storeBusinessTimeDtoList[0].startTime
        let endTime = this.detailData.storeBusinessTimeDtoList[0].endTime
        if (startTime && endTime) {
          this.$set(this.detailData, 'businessTimes', startTime + '~' + endTime)
        }
      }
      if (this.detailData.latitude && this.detailData.longitude) {
        this.mapShow = true
      }
      this.center = latLng(this.detailData.latitude, this.detailData.longitude)
      this.marker = [this.detailData.latitude, this.detailData.longitude]
    },
    // 禁烟类型
    getSmokingType(smokingType) {
      // console.log(smokingType)
      var s = ''
      if (!smokingType) {
        return s
      }
      if (smokingType.toString().indexOf('1') > -1) {
        s += this.$t('禁止吸烟')
      }
      if (smokingType.toString().indexOf('2') > -1) {
        if (s.length > 0) {
          s += ','
        }
        s += this.$t('店内可吸烟')
      }
      if (smokingType.toString().indexOf('3') > -1) {
        s += '(' + this.$t('有吸烟室') + ')'
      }
      if (smokingType.toString().indexOf('4') > -1) {
        if (s.length > 0) {
          s += ','
        }
        s += this.$t('禁止吸烟')
      }
      // console.log(s)
      return s
    },
  },
}
</script>
<style lang='scss' scoped>
.banner-box {
  width: 100%;
  min-height: 450px;
  // background: url('../../assets/images/banner-02.png');
  // background: linear-gradient(to top left,#F7971E,#FFD200);
  .banner-wrapper {
    padding: 10px;
    background-color: rgba(245, 242, 245, 0.5);
    .top-crumb {
      margin-top: 10px;
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      font-size: 16px;
      ::v-deep .el-link {
        font-size: 16px;
        &:hover {
          color: #e7b530;
        }
      }
    }
    .banner-content-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      width: 100%;
      min-height: 400px;
      padding: 20px;
      box-sizing: border-box;
      background: #fff;
      border: 1px solid #dcdfe6;
      border-radius: 5px;
      .bottom-order {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        .detail-content {
          display: flex;
          justify-content: flex-start;
        }
      }
      .top-crumb span {
        padding: 0 6px;
      }
      .center-info {
        display: flex;
        width: 100%;
        .banner-left {
          width: 300px;
          height: auto;
          .swiper-box {
            width: 300px;
            min-height: 300px;
            .list-left-image {
              width: 260px;
              height: 260px;
            }
            .list-image-thumbs {
              margin-top: 8px;
              width: 93px;
              height: 93px;
            }
          }
          .banner-image {
            width: 300px;
            height: 300px;
          }
        }
        .food-money {
          margin-top: 20px;
          display: flex;
          align-items: center;
          .cost-text {
            color: #000;
          }
        }
        .banner-content {
          flex: 1;
          margin-left: 16px;
          font-size: 14px;
        }
      }
    }
  }
}
.mt-20 {
  margin-top: 16px;
}
.menu-box1 {
  width: 100%;
  height: 50px;
  background-color: rgba(245, 242, 245, 0.5);
  margin-top: -1px;
  overflow: hidden;
  border: 0px;
}
.menu-list {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  .menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin: 0 20px;
    font-size: 18px;
    font-weight: 600;
    color: #2d333f;
  }
  .menu-active {
    color: #e7b530;
    border-bottom: 3px solid #e7b530;
  }
}
.menu-box2 {
  position: fixed;
  top: 60px;
  left: 0;
  height: 50px;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #dcdfe6;
  border-top: 1px solid #dcdfe6;
  z-index: 10000;
}
.content-box {
  display: flex;
  width: 100%;
  height: auto;
  // background-color: rgba(245,242,245, 0.5);
  border-top: 1px solid #dcdfe6;
  .left-box {
    flex: 0.97;
    margin-top: 20px;
    margin-right: 10px;
    padding: 0px 20px 0;
    background-color: #fff;
    border: 1px solid #dcdfe6;
    border-radius: 5px;
    box-sizing: border-box;
    .store-info {
      .store-title {
        margin: 30px 0 40px;
        border-left: 3px solid #e7b530;
        padding-left: 20px;
        font-size: 18px;
        font-weight: 600;
      }
    }
    .social-media {
      .social-title {
        margin: 30px 0 40px;
        border-left: 3px solid #e7b530;
        padding-left: 20px;
        font-size: 18px;
        font-weight: 600;
      }
      .social-content {
        width: 100%;
        min-height: 100px;
        .social-list {
          display: flex;
          justify-content: flex-start;
          .social-item {
            margin: 10px 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            cursor: pointer;
            .social-logo {
              margin-bottom: 10px;
              width: 40px;
            }
            .social-text {
              font-size: 12px;
            }
            &:hover {
              .social-text {
                color: #e7b530;
              }
            }
          }
        }
      }
    }
    .infection-info {
      .infection-title {
        margin: 30px 0 40px;
        border-left: 3px solid #e7b530;
        padding-left: 20px;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
  .right-box {
    width: 360px;
    .right-fixed-box {
      width: 360px;
      min-height: 700px;
      background: #fff;
      z-index: -1;
    }
    .right-content-box {
      background: #fff;
      width: 100%;
      min-height: 700px;
    }
  }
}
.right-aside {
  background: #fff;
  margin-top: 20px;
  padding: 10px;
  width: 100%;
  min-height: 600px;
  -moz-box-shadow: 1px 1px 5px #e0e3e2;
  -webkit-box-shadow: 1px 1px 5px #e0e3e2;
  box-shadow: 1px 1px 5px #e0e3e2;
  .aside-map {
    width: 100%;
    height: 200px;
  }
  .aside-title {
    margin: 10px 0;
    font-size: 16px;
    font-weight: 600;
    color: #2d333f;
  }
  .aside-Announcement{
    margin-bottom: 10px;
  }
  .aside-payType {
    line-height: 20px;
    font-size: 14px;
  }
  .aside-phone {
    font-size: 16px;
    color: #e7b530;
  }
}
.banner-content-title {
  font-size: 24px;
  font-weight: 400;
}
.store-service {
  margin-top: 22px;
  font-size: 16px;
  line-height: 22px;
}
.list-service {
  margin-top: 20px;
  height: 20px;
  line-height: 20px;
  display: flex;
  align-items: center;
  .service-item {
    display: flex;
    align-items: center;
    height: 20px;
    line-height: 20px;
    .service-logo {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
    .service-content {
      font-size: 12px;
    }
  }
}
.store-rate {
  margin: 10px 0 20px;
  ::v-deep .el-rate__icon {
    font-size: 32px;
  }
  ::v-deep .el-rate__text {
    font-size: 28px;
    font-weight: 800;
  }
}
.aside-address {
  padding-bottom: 8px;
  .address-text {
    line-height: 24px;
    white-space: pre-wrap;
  }
}
.aside-week {
  margin-bottom: 12px;
}
.aside-time {
  margin-bottom: 12px;
}
.not-image {
  width: 300px;
  height: 300px;
  background: rgb(244, 244, 244);
}
.infection-content li {
  margin-left: 18px;
  list-style: initial;
  line-height: 30px;
}
.not-content {
  color: #c0c4cc;
}
.couponBox {
  min-width: 106px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  color: #fff;
  box-sizing: border-box;
  background: radial-gradient(transparent 0, transparent 4px, #ffb937 4px);
  background-size: 15px 14px;
  background-position: 8px 0px;
  .coupon-warper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #ffb937;
    line-height: 18px;
    width: 100%;
    height: 100%;
  }
}
.couponValue {
  text-align: center;
  margin-top: 6px;
  font-weight: 800;
  font-size: 20px;
  color: #fff;
}
.go-to-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #dcdfe6;
  border-radius: 5px;
  background: #409eff;
  width: 100%;
  height: 40px;
  font-size: 16px;
  color: #fff;
}
.content-warper {
  width: 100%;
  height: auto;
  margin-top: -10px;
  padding-bottom: 20px;
  background-color: rgba(245, 242, 245, 0.5);
}
.video-img-list {
  margin-top: 10px;
}
.video-select {
  border: 1px solid #ffb937;
  box-sizing: border-box;
}
</style>

