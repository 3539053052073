<template>
  <div v-loading="dataLoading" ref="ContainerViews">
    <!-- 全屏 -->
    <div class="all-container">
      <!-- 背景全屏 -->
      <banner-content ref="BannerContent" />
    </div>
    <!-- 全屏 -->
    <div class="all-container" style="margin-top: -1px">
      <!-- 一直固定在顶部的菜单 -->
      <div v-if="showFlag" class="menu-box2">
        <div class="container">
          <div class="menu-list">
            <div v-for="item in menus" :key="item.value" class="menu-item" :class="{'menu-active': active === item.value }" @click="changeMenu(item.value)">{{ item.label }}</div>
          </div>
        </div>
      </div>
      <!-- 菜单 -->
      <div v-else class="menu-box1">
        <div class="container">
          <div class="menu-list">
            <div v-for="item in menus" :key="item.value" class="menu-item" :class="{'menu-active': active === item.value }" @click="changeMenu(item.value)">{{ item.label }}</div>
          </div>
        </div>
      </div>

    </div>
    <div class="all-container">
      <div class="content-warper">
        <div class="container">
          <div class="content-box">
            <!-- 左边内容 -->
            <left-content ref="LeftContent" />

            <!-- 右边内容 -->
            <right-content ref="RightContent" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storeDetail } from '@/api/store'
import { formatMoney } from '@/utils/money'
import { lanMap, dealWeekDay } from '@/utils/util'
// import { getLanguage } from '@/lang/index'
import { weeks, storeShopType, storeService, storePayType, measuresList, onlinePayType, qrPayType, getDefaultLon, getDefaultLat } from '@/utils/public_data'
import { latLng, icon } from "leaflet"
import i18n from '@/lang/index'
// LIcon,
import L from 'leaflet'
import 'proj4'
import 'proj4leaflet'
import 'leaflet.chinatmsproviders'
// import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet"
import Swiper from 'swiper'
import LeftContent from './left-content'
import RightContent from './right-content'
import BannerContent from './banner-content'

export default {
  // LIcon LMap, LTileLayer, LMarker, LPopup,
  components: {  LeftContent, RightContent, BannerContent },
  filters: {
    moneyFilter(val) {
      return formatMoney(val)
    },
    timeFilter(val) {
      return val.substring(0,5)
    }
  },
  data() {
    return {
      storeId: 0,
      menus: [
        { value: 1, label: this.$t('景点总览') },
        { value: 2, label: 'SNS' },
        { value: 3, label: this.$t('防疫对策') }
      ],
      detailData: {},
      // center: { lng: 139.764887, lat: 35.682084 },
      zooms: 15,
      active: 1,
      showFlag: false,
      weeks: weeks(),
      shopType: storeShopType(),
      service: storeService(),
      payType: storePayType(),
      onlineType: onlinePayType(),
      qrType: qrPayType(),
      facebook: require('@/assets/images/media-01.png'),
      twitter: require('@/assets/images/media-05.png'),
      youtube: require('@/assets/images/media-02.png'),
      ins: require('@/assets/images/media-03.png'),
      tictok: require('@/assets/images/media-04.png'),
      dataLoading: false,
      BMap: null,
      map: null,
      thumbsSwiper: {},
      swiper: {},
      zoom: 14,
      mapShow: false,
      center: latLng(getDefaultLat(), getDefaultLon()),
      url: 'http://www.google.jp/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}',
      attribution: '&copy; <a href="http://www.google.jp">Google</a>',
      subdomains: '',
      currentZoom: 11.5,
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5
      },
      crs: L.CRS.EPSG3857,
      tms: false,
      tileLayerOptions: {
        maxZoom: 18,
        minZoom: 8
      },
      marker: [],
      iconUrl: require('@/assets/images/address.png'),
      addressIcon: icon({
        iconUrl: require('@/assets/images/address.png'),
        iconSize: [32, 32],
        iconAnchor: [16, 10]
      }),
      showMap: true,
      videoShow: false,
      videoPlay: ''
    }
  },
  created() {
    // console.log('this.$route.query.id', this.$route.query.id)
    this.storeId = this.$route.query.id
    lanMap(this, this.lan)
    // console.log(this.lan)
    this.loadData()
  },
  mounted() {
    window.addEventListener("scroll", this.showTop);
    this.$nextTick(() => {
      if (this.$refs.ContainerViews) {
        this.$refs.ContainerViews.scrollIntoView({block: "start"})
      }
    })
  },
  computed: {
    lan() {
      return i18n.locale
    }
  },
  watch: {
    center: {
      handler(newVal) {
        console.log(newVal)
      },
      deep: true
    },
    lan(newVal) {
      lanMap(this, newVal)
    }
  },
  methods: {
    handle(val) {
      this.$router.push(val)
    },
    dealWeekDays(list) {
      // console.log(dealWeekDay(list))
      return dealWeekDay(list)
    },
    zoomUpdated (zoom) {
      this.zoom = zoom;
    },
    centerUpdated (center) {
      this.center = center;
    },
    boundsUpdated (bounds) {
      this.bounds = bounds;
    },
    initSwiper() {
      this.thumbsSwiper = new Swiper(`.img-thumbs`, {
        spaceBetween: 10,
        slidesPerView: 3,
        nextButton: `.swiper-button-next`,
        prevButton: `.swiper-button-prev`,
        loop: false,
        watchSlidesVisibility: true,/*避免出现bug*/
      })
      this.swiper = new Swiper(`.img-top`, {
        spaceBetween: 10,
        nextButton: `.swiper-button-next`,
        prevButton: `.swiper-button-prev`,
        speed: 1000,
        loop: false,
        controller:{
          control: this.thumbsSwiper,
        },
        thumbs: {
          swiper: this.thumbsSwiper,
        }
      })
    },
    loadData(){
      this.dataLoading = true
      this.mapShow = false
      storeDetail(this.storeId).then(response => {
        if(response && response.data) {
          this.detailData = response.data
          if(this.detailData.treeId === 1) {
            this.menus = [ { value: 1, label: this.$t('景点总览') }, { value: 2, label: this.$t('餐厅SNS') }, { value: 3, label: this.$t('防疫对策') } ]
          } else {
            this.menus = [ { value: 1, label: this.$t('景点总览') }, { value: 2, label: 'SNS' }, { value: 3, label: this.$t('防疫对策') } ]
          }
          this.$refs.LeftContent.init(this.detailData)
          this.$refs.RightContent.init(this.detailData)
          this.$refs.BannerContent.init(this.detailData)
          let minPrice = this.detailData.breakfast
          let maxPrice = this.detailData.lunch
          if (minPrice==0 || minPrice > this.detailData.dinner) {
            minPrice = this.detailData.dinner
          }
          if (minPrice==0 || minPrice > this.detailData.lunch) {
            minPrice = this.detailData.lunch
          }
          if (maxPrice==0 || maxPrice < this.detailData.dinner) {
            maxPrice = this.detailData.dinner
          }
          if (maxPrice==0 || maxPrice < this.detailData.breakfast) {
            maxPrice = this.detailData.breakfast
          }
          this.$set(this.detailData, 'minPrice', minPrice)
          this.$set(this.detailData, 'maxPrice', maxPrice)
          if (this.detailData.storeImg) {
            this.$set(this.detailData, 'imgList', this.detailData.storeImg.split(','))
          }
          // console.log(this.detailData.imgList)
          if (this.detailData.imgList && this.detailData.imgList.length > 0) {
            this.$nextTick(() => {
              this.initSwiper()
            })
          }
          if (this.detailData.storeType) {
            this.$set(this.detailData, 'storeTypes', this.detailData.storeType.split(','))
          }
          if(this.detailData.stipulationRestDays) {
            // eslint-disable-next-line no-useless-escape
            var stipulationRestDaysArr = this.detailData.stipulationRestDays.split('\/\/')
            this.$set(this.detailData, 'restDay', stipulationRestDaysArr[0].replace('undefined', '').replace(/^,+/,"").split(',').concat(stipulationRestDaysArr[1]))
          }
          if (this.detailData.allowPayType) {
            this.$set(this.detailData, 'payTypes', this.detailData.allowPayType.split(','))
          }
          if (this.detailData.allowOnlinePay) {
            this.$set(this.detailData, 'onlineTypes', this.detailData.allowOnlinePay.split(','))
          }
          if (this.detailData.allowQrPay) {
            this.$set(this.detailData, 'qrTypes', this.detailData.allowQrPay.split(','))
          }
          if (this.detailData.storeDescribe) {
            this.$set(this.detailData, 'measures', this.detailData.storeDescribe.split(','))
          }
          if (this.detailData.storeBusinessTimeDtoList && this.detailData.storeBusinessTimeDtoList.length > 0) {
            this.detailData.storeBusinessTimeDtoList.map(item => {
              this.$set(item, 'weeks', item.week.split(',').sort())
            })
            let startTime = this.detailData.storeBusinessTimeDtoList[0].startTime
            let endTime = this.detailData.storeBusinessTimeDtoList[0].endTime
            if(startTime && endTime) {
              this.$set(this.detailData, 'businessTimes', startTime + "~" + endTime)
            }
          }
          if (this.detailData.latitude && this.detailData.longitude) {
            this.mapShow = true
          }
          if (this.detailData.videoUrl) {
            if (this.detailData.videoUrl.split(',').length > 0) {
              this.menus.unshift({ value: 4, label: this.$t('景点视频') })
            }
          }
          // mtMap.centerAndZoom(new this.BMap.Point(this.detailData.latitude,this.detailData.longitude),11)
          // console.log('this.detailData', this.detailData)
          this.center = latLng(this.detailData.latitude, this.detailData.longitude)
          this.marker = [this.detailData.latitude, this.detailData.longitude]
          // this.marker.push(this.detailData.latitude)
          // this.marker.push(this.detailData.longitude)
          this.dataLoading = false
        }
      })
    },
    changeMenu(value) {
      this.active = value
      // 这里可以进行页面的跳动
      // this.$refs.FirstStep.scrollIntoView({block: "start"})
      if (this.active === 1) {
        this.$refs.LeftContent.scrollGoto('StoreInfo', 'center')
      }
      if (this.active === 2) {
        this.$refs.LeftContent.scrollGoto('Social', 'center')
      }
      if (this.active === 3) {
        this.$refs.LeftContent.scrollGoto('Infection', 'center')
      }
      if (this.active === 4) {
        this.$refs.LeftContent.scrollGoto('StoreVideo', 'center')
      }
    },
    measuresName(measure) {
      var measures = measuresList()
      if (measures) {
        for (var i = 0; i < measures.length; i++){
          if(measures[i].value === measure){
            return measures[i].label
          }
        }
      }
      return ''
    },
    getRestDay(restItem) {
      if (this.weeks[restItem]) {
        return this.weeks[restItem]
      } else {
        return restItem
      }
    },
    getYoutubeUrl(videoId) {
      if(videoId.indexOf("http") === 0) {
        return videoId
      }
      return 'https://www.youtube.com/embed/' + videoId
    },
    // 控制menu的出现或消失
    showTop() {
      if (!!document.documentElement.scrollTop && document.documentElement.scrollTop > 300 ) {
          this.showFlag = true
        } else {
          this.showFlag = false
        }
    },
    // 获取地址label样式，英文环境处理
    getAddressLabelStyle() {
      if (this.$i18n.locale === 'en') {
        return 'width: 76px;'
      }
      return 'width: 50px;'
    },
    // 禁烟类型
    getSmokingType(smokingType) {
      // console.log(smokingType)
      var s = ''
      if (!smokingType) {
        return s
      }
      if (smokingType.toString().indexOf('1') > -1) {
        s += this.$t('禁止吸烟')
      }
      if (smokingType.toString().indexOf('2') > -1) {
        if (s.length > 0) {
          s += ','
        }
        s += this.$t('店内可吸烟')
      }
      if (smokingType.toString().indexOf('3') > -1){
        s += '(' + this.$t('有吸烟室') + ')'
      }
      if (smokingType.toString().indexOf('4') > -1) {
        if (s.length > 0) {
          s += ','
        }
        s += this.$t('禁止吸烟')
      }
      // console.log(s)
      return s
    }
  }
}
</script>
<style lang='scss' scoped>
.banner-box {
  width: 100%;
  min-height: 450px;
  // background: url('../../assets/images/banner-02.png');
  // background: linear-gradient(to top left,#F7971E,#FFD200);
  .banner-wrapper {
    padding: 10px;
    background-color: rgba(245,242,245, 0.5);
    .top-crumb {
        margin-top: 10px;
        margin-bottom: 10px;
        display: flex;
        justify-content: flex-start;
        width: 100%;
        font-size: 16px;
        ::v-deep .el-link {
          font-size: 16px;
          &:hover {
            color: #E7B530;
          }
        }
      }
    .banner-content-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      width: 100%;
      min-height: 400px;
      padding: 20px;
      box-sizing: border-box;
      background: #fff;
      border: 1px solid #DCDFE6;
      border-radius: 5px;
      .bottom-order {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        .detail-content {
          display: flex;
          justify-content: flex-start;
        }
      }
      .top-crumb span {
        padding: 0 6px;
      }
      .center-info {
        display: flex;
        width: 100%;
        .banner-left {
          width: 300px;
          height: auto;
          .swiper-box {
            width: 300px;
            min-height: 300px;
            .list-left-image {
              width: 260px;
              height: 260px;
            }
            .list-image-thumbs {
              margin-top: 8px;
              width: 93px;
              height: 93px;
            }
          }
          .banner-image {
            width: 300px;
            height: 300px;
          }
        }
        .food-money {
          margin-top: 20px;
          display: flex;
          align-items: center;
          .cost-text {
            color: #000;
          }
        }
        .banner-content {
          flex: 1;
          margin-left: 16px;
          font-size: 14px;
        }
      }
    }
  }
}
.mt-20 {
  margin-top: 16px;
}
.menu-box1 {
  width: 100%;
  height: 50px;
  background-color: rgba(245,242,245, 0.5);
  margin-top: -1px;
  overflow: hidden;
  border: 0px;
}
.menu-list {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  .menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin: 0 20px;
    font-size: 18px;
    font-weight: 600;
    color: #2D333F;
  }
  .menu-active {
    color: #E7B530;
    border-bottom: 3px solid #E7B530;
  }
}
.menu-box2 {
  position: fixed;
  top: 60px;
  left: 0;
  height: 50px;
  width: 100%;
  background: #fff;
  border-bottom:  1px solid #DCDFE6;
  border-top:  1px solid #DCDFE6;
  z-index: 2000;
}
.content-box {
  display: flex;
  width: 100%;
  height: auto;
  // background-color: rgba(245,242,245, 0.5);
  border-top: 1px solid #DCDFE6;
  .left-box {
    flex: 0.97;
    margin-top: 20px;
    margin-right: 10px;
    padding: 0px 20px 0;
    background-color: #fff;
    border: 1px solid #DCDFE6;
    border-radius: 5px;
    box-sizing: border-box;
    .store-info {
      .store-title {
        margin: 30px 0 40px;
        border-left: 3px solid #E7B530;
        padding-left: 20px;
        font-size: 18px;
        font-weight: 600;
      }
    }
    .social-media {
      .social-title {
        margin: 30px 0 40px;
        border-left: 3px solid #E7B530;
        padding-left: 20px;
        font-size: 18px;
        font-weight: 600;
      }
      .social-content {
        width: 100%;
        min-height: 100px;
        .social-list {
          display: flex;
          justify-content: flex-start;
          .social-item {
            margin: 10px 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            cursor:pointer;
            .social-logo {
              margin-bottom: 10px;
              width: 40px;
            }
            .social-text {
              font-size: 12px;
            }
            &:hover {
              .social-text {
                color: #E7B530;
              }
            }
          }
        }
      }
    }
    .infection-info {
      .infection-title {
        margin: 30px 0 40px;
        border-left: 3px solid #E7B530;
        padding-left: 20px;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
  .right-box {
    width: 360px;
    .right-fixed-box {
      width: 360px;
      min-height: 700px;
      background: #fff;
      z-index: -1;
    }
    .right-content-box {
      background: #fff;
      width: 100%;
      min-height: 700px;
    }
  }
}
.right-aside {
  background: #fff;
  margin-top: 20px;
  padding: 10px;
  width: 100%;
  min-height: 600px;
  -moz-box-shadow:1px 1px 5px #E0E3E2;
  -webkit-box-shadow:1px 1px 5px #E0E3E2;
  box-shadow:1px 1px 5px #E0E3E2;
  .aside-map {
    width: 100%;
    height: 200px;
  }
  .aside-title {
    margin: 10px 0;
    font-size: 16px;
    font-weight: 600;
    color: #2D333F;
  }
  .aside-payType {
    line-height: 20px;
    font-size: 14px;
  }
  .aside-phone {
    font-size: 16px;
    color:#E7B530;
  }
}
.banner-content-title {
  font-size: 24px;
  font-weight: 400;
}
.store-service {
  margin-top: 22px;
  font-size: 16px;
  line-height: 22px;
}
.list-service {
  margin-top: 20px;
  height: 20px;
  line-height: 20px;
  display: flex;
  align-items: center;
  .service-item {
    display: flex;
    align-items: center;
    height: 20px;
    line-height: 20px;
    .service-logo {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
    .service-content {
      font-size: 12px;
    }
  }
}
.store-rate {
  margin: 10px 0 20px;
  ::v-deep .el-rate__icon {
    font-size: 32px;
  }
  ::v-deep .el-rate__text {
    font-size: 28px;
    font-weight: 800;
  }
}
.aside-address{
  padding-bottom: 8px;
  .address-text {
    line-height: 24px;
    white-space: pre-wrap;
  }
}
.aside-week {
  margin-bottom: 12px;
}
.aside-time {
  margin-bottom: 12px;
}
.not-image {
  width: 300px;
  height: 300px;
  background: rgb(244,244,244);
}
.infection-content li {
  margin-left: 18px;
  list-style: initial;
  line-height: 30px;
}
.not-content {
  color: #C0C4CC;
}
.couponBox {
  min-width: 106px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  color: #fff;
  box-sizing: border-box;
  background: radial-gradient(transparent 0, transparent 4px, #ffb937 4px);
  background-size: 15px 14px;
  background-position: 8px 0px;
  .coupon-warper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #ffb937;
    line-height: 18px;
    width: 100%;
    height: 100%;
  }
}
.couponValue {
  text-align: center;
  margin-top: 6px;
  font-weight: 800;
  font-size: 20px;
  color: #fff;
}
.go-to-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #DCDFE6;
  border-radius: 5px;
  background:#409EFF;
  width: 100%;
  height: 40px;
  font-size: 16px;
  color: #fff;
}
.content-warper {
  width: 100%;
  height: auto;
  margin-top: -10px;
  padding-bottom: 20px;
  background-color: rgba(245,242,245, 0.5);
}
.video-img-list {
  margin-top: 10px;
}
.video-select {
  border: 1px solid #ffb937;
  box-sizing: border-box;
}
</style>
