<template>
  <div class="all-container">
    <div class="top-crumb">
      <el-link :underline="false" @click="handle('/home')">{{
        $t("首页")
      }}</el-link>
      <span>&gt;</span>
      <el-link v-if="detailData.treeId === 1" :underline="false" @click="handle('/index')">{{ $t("餐厅") }}</el-link>
      <el-link v-if="detailData.treeId === 2" :underline="false" @click="handle('/department')">{{ $t("观光情报") }}</el-link>
      <el-link disabled :underline="false">&gt; {{ detailData.storeName }}</el-link>
    </div>
    <div v-if="detailData.imgList && detailData.imgList.length > 0" class="img-box">
      <div class="swiper-container img-top">
        <div class="swiper-wrapper">
          <div v-for="(imgItem, imgIndex) in detailData.imgList" :key="imgIndex" class="swiper-slide">
            <el-image :src="imgItem" class="list-image" fit="contain"></el-image>
          </div>
          <div class="swiper-pagination" style="height:30px;width: 100%"></div>
          <!--分页器。如果放置在swiper-container外面，需要自定义样式。-->
        </div>
      </div>
    </div>
    <div class="contain">
      <div class="container-mb">
        <div class="content-title">{{ detailData.storeName }}</div>
        <div class="content-rate">
          <!-- <el-rate v-model="detailData.storeScore" disabled :show-score="false" text-color="#ff9900"  score-template="{value}"> </el-rate> -->
        </div>
        <div v-if="detailData.treeId === 1" class="mt-20">
          <span class="title-text">{{ $t("类型") }}：</span>
          <span v-if="detailData.storeTypes && detailData.storeTypes.length > 0">
            <span v-for="(type, typeIndex) in detailData.storeTypes" :key="typeIndex">
              <span v-show="typeIndex > 0">·</span>
              {{ $t(shopType[type]) }}
            </span>
          </span>
          <span v-else>-</span>
        </div>
        <div class="content-time mt-10">
          <span v-if="
              detailData.storeBusinessTimeDtoList &&
                detailData.storeBusinessTimeDtoList.length > 0
            " class="title-text">{{ $t("营业时间") }}：</span>
          <div v-if="
              detailData.storeBusinessTimeDtoList &&
                detailData.storeBusinessTimeDtoList.length > 0
            ">
            <div v-for="timeItem in detailData.storeBusinessTimeDtoList" :key="timeItem.id" class="flex-box mb-10">
              <div v-if="timeItem.weeks.length === 1" class="aside-week">
                <span>{{ $t(weeks[timeItem.weeks[0]]) }}</span>
              </div>
              <div v-else class="aside-week">
                <span v-for="(weekItem, weekIndex) in dealWeekDays(timeItem.week)" :key="weekItem">
                  <!-- {{ $t(weeks[weekItem]) }} -->
                  <span v-if="weeks[weekItem].indexOf('~') > -1">{{ $t(weeks[weekItem].split("~")[0]) }}~{{
                      $t(weeks[weekItem].split("~")[1])
                    }}</span>
                  <span v-else>{{ $t(weeks[weekItem]) }}</span>
                  <span v-if="weekIndex !== dealWeekDays(timeItem.week).length - 1">、</span>
                </span>
              </div>
              <div class="aside-time">
                <span>{{ timeItem.startTime | timeFilter }}</span>
                <span>-</span>
                <span>{{ timeItem.endTime | timeFilter }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="detailData.treeId === 1" class="content-money mt-10">
          <!-- <p v-if="detailData.breakfast > 0" class="mt-10"><span class="title-text">{{ $t('早餐') }}：</span><span class="cost-text">¥ {{ detailData.breakfast | moneyFilter }}<span v-if="detailData.maxBreakfast"> - {{ detailData.maxBreakfast	| moneyFilter }}</span></span></p> -->
          <p v-if="detailData.lunch > 0" class="mt-10">
            <span class="title-text">{{ $t("午餐") }}：</span><span class="cost-text">¥ {{ detailData.lunch | moneyFilter
              }}<span v-if="detailData.maxLunch">
                - {{ detailData.maxLunch | moneyFilter }}</span></span>
          </p>
          <p v-if="detailData.dinner > 0" class="mt-10">
            <span class="title-text">{{ $t("晚餐") }}：</span><span class="cost-text">¥ {{ detailData.dinner | moneyFilter
              }}<span v-if="detailData.maxDinner">
                - {{ detailData.maxDinner | moneyFilter }}</span></span>
          </p>
        </div>
        <div class="mt-10">
          <el-row v-if="detailData.featureProvide">
            <span class="title-text">{{ $t("特典提供") }}：</span>
            <span>{{ detailData.featureProvide }}</span>
          </el-row>
          <span v-else></span>
        </div>
        <div v-if="detailData.treeId === 1" class="content-discount mt-10">
          <div class="cost-discount">
            <div class="cost-title mb-10">
              <span class="title-text">{{ $t("大阪乐游劵折扣率") }}：</span>
              <span v-if="detailData.osakaSaleRate" class="const-red-content">{{ detailData.osakaSaleRate }}%</span><span v-else>-</span>
            </div>
            <!-- JR ALLPASS 和 Tokyo Joyful 折扣率 -->
            <!-- <div class="cost-title mb-10">
              <span class="title-text">JR ALLPASS {{ $t("折扣率") }}：</span><span v-if="detailData.jrSaleRate" class="const-red-content">{{ detailData.jrSaleRate }}%</span><span v-else>-</span>
            </div>
            <div class="cost-title">
              <span class="title-text">Tokyo Joyful {{ $t("折扣率") }}：</span><span v-if="detailData.tokyoSaleRate" class="const-red-content">{{ detailData.tokyoSaleRate }}%</span><span v-else>-</span>
            </div> -->
            <div class="cost-title">
              <span class="title-text">Tripellet {{ $t("折扣率") }}：</span><span v-if="detailData.tripelletSaleRate" class="const-red-content">{{ detailData.tripelletSaleRate }}%</span><span v-else>-</span>
            </div>
          </div>
        </div>
        <div v-if="
            detailData.treeId === 2 &&
              (detailData.osakaSaleRate ||
                detailData.jrSaleRate ||
                detailData.tokyoSaleRate ||
                detailData.tripelletSaleRate)
          " class="content-discount mt-10">
          <div class="cost-discount">
            <div v-if="detailData.osakaSaleRate" class="cost-title mb-10">
              <span class="title-text">{{ $t("大阪乐游劵折扣率") }}：</span>
              <span v-if="detailData.osakaSaleRate" class="const-red-content">{{ detailData.osakaSaleRate }}%</span><span v-else>-</span>
            </div>
            <!-- <div v-if="detailData.jrSaleRate" class="cost-title mb-10">
              <span class="title-text">JR ALLPASS {{ $t("折扣率") }}：</span><span v-if="detailData.jrSaleRate" class="const-red-content">{{ detailData.jrSaleRate }}%</span><span v-else>-</span>
            </div>
            <div v-if="detailData.tokyoSaleRate" class="cost-title">
              <span class="title-text">Tokyo Joyful {{ $t("折扣率") }}：</span><span v-if="detailData.tokyoSaleRate" class="const-red-content">{{ detailData.tokyoSaleRate }}%</span><span v-else>-</span>
            </div> -->
            <div v-if="detailData.tripelletSaleRate" class="cost-title">
              <span class="title-text">Tripellet {{ $t("折扣率") }}：</span><span v-if="detailData.tripelletSaleRate" class="const-red-content">{{ detailData.tripelletSaleRate }}%</span><span v-else>-</span>
            </div>
          </div>
        </div>
        <div class="content-money mt-10">
          <p v-if="
              detailData.recommendActivity &&
                detailData.recommendActivity.indexOf('5') !== -1
            ">
            <span class="title-text">Double OFF：</span>¥ 500
          </p>
        </div>
        <div class="content-money mt-10">
          <div v-show="
              detailData.recommendActivity &&
                detailData.recommendActivity.indexOf('3') !== -1
            " class="go-to-box">
            Go To トラベル 店舗
          </div>
        </div>
        <el-button  size="medium" round class="moreBut" @click="() => (showDetail = !showDetail)">{{ $t("更多") }}
          <i v-if="showDetail" class="el-icon-arrow-up"></i>
          <i v-else class="el-icon-arrow-down"></i>
        </el-button>
        <el-divider v-if="showDetail"></el-divider>
        <div v-if="showDetail">
          <div v-if="detailData.treeId === 1">
            <span class="title-text">{{ $t("电话番号") }}：</span>
            <span v-if="detailData.phone">{{ detailData.phone }}</span>
            <span v-else>-</span>
          </div>
          <div v-if="detailData.treeId === 2 && detailData.phone">
            <span class="title-text">{{ $t("电话番号") }}：</span>
            <span v-if="detailData.phone">{{ detailData.phone }}</span>
          </div>
          <div v-if="detailData.treeId === 1" class="mt-10">
            <span class="title-text">{{ $t("官方网址") }}：</span>
            <span v-if="detailData.officialWebsite">
              <el-link type="primary" :href="detailData.officialWebsite" :underline="false" target="_blank">{{ detailData.officialWebsite }}</el-link>
            </span>
            <span v-else>-</span>
          </div>
          <div v-if="detailData.treeId === 2 && detailData.officialWebsite" class="mt-10">
            <span class="title-text">{{ $t("官方网址") }}：</span>
            <span v-if="detailData.officialWebsite">
              <el-link type="primary" :href="detailData.officialWebsite" :underline="false" target="_blank">{{ detailData.officialWebsite }}</el-link>
            </span>
          </div>
          <div v-if="detailData.payTypes && detailData.payTypes.length > 0" class="mt-10">
            <span class="title-text">{{ $t("付款方式") }}：</span>
            <span class="lh-16" v-if="detailData.payTypes && detailData.payTypes.length > 0">
              <span v-for="(payTypeItem, payTypeIndex) in detailData.payTypes" :key="payTypeIndex" class="aside-payType">
                {{ $t(payType[payTypeItem]) }}
                <span v-if="payTypeItem === '8'">
                  (
                  <span v-for="(onlineItem, onlineIndex) in detailData.onlineTypes" :key="onlineIndex">{{ $t(onlineType[onlineItem]) }}
                    <span v-if="onlineIndex < detailData.onlineTypes.length - 1">,</span>
                  </span>
                  )
                </span>
                <span v-if="payTypeItem === '9'">
                  (
                  <span v-for="(qrItem, qrIndex) in detailData.qrTypes" :key="qrIndex">{{ $t(qrType[qrItem]) }}
                    <span v-if="qrIndex < detailData.qrTypes.length - 1">,</span>
                  </span>
                  )
                </span>
                <span v-if="payTypeIndex < detailData.payTypes.length - 1">,</span>
              </span>
            </span>
          </div>
          <div v-if="detailData.boxNumber || detailData.seatsNumber" class="mt-10">
            <span class="title-text">{{ $t("店内施設") }}：</span>
            <span>
              <span v-if="detailData.boxNumber" class="mr-20">{{ $t("包厢容纳人数") }}{{ detailData.boxNumber }}</span>
              <!-- <span v-else class="mr-20">{{ $t('包厢容纳人数') }}-</span> -->
              <span v-if="detailData.seatsNumber">{{ $t("席位数") }}{{ detailData.seatsNumber }}</span>
              <!-- <span v-else>{{ $t('席位数') }}-</span> -->
            </span>
          </div>
          <div class="mt-10">
            <span v-if="detailData.stipulationRestDays" class="title-text">{{ $t("公休日") }}：</span>
            <span v-if="
                detailData.stipulationRestDays &&
                  !detailData.stipulationRestDays.startsWith('8') &&
                  !detailData.stipulationRestDays.startsWith('9')
              ">
              <span v-for="(restItem, restIndex) in detailData.restDay" :key="restItem">{{ $t(getRestDay(restItem))
                }}<span v-if="restIndex < detailData.restDay.length - 1" style="margin-right: 10px;">,</span></span>
            </span>
            <span v-else>
              <span v-if="
                  detailData.stipulationRestDays &&
                    detailData.stipulationRestDays.startsWith('8')
                ">{{ $t("不定期休息") }}</span>
              <span v-if="
                  detailData.stipulationRestDays &&
                    detailData.stipulationRestDays.startsWith('9')
                ">{{ $t("无休") }}</span>
              <span v-if="detailData.restDay && detailData.restDay.length > 0">，{{ detailData.restDay[1] }}</span>
            </span>
          </div>
          <div v-if="detailData.treeId === 1" class="mt-10">
            <span class="title-text">{{ $t("交通路线") }}：</span>
            <span v-if="detailData.trafficRoute">{{
              detailData.trafficRoute
            }}</span>
            <span v-else>-</span>
          </div>
          <div v-if="detailData.treeId === 2 && detailData.trafficRoute" class="mt-10">
            <span class="title-text">{{ $t("交通路线") }}：</span>
            <span v-if="detailData.trafficRoute">{{
              detailData.trafficRoute
            }}</span>
          </div>
          <div v-if="
              detailData.specialMeal &&
                detailData.specialMeal != '' &&
                detailData.specialMeal != '0'
            " class="mt-10">
            <span class="title-text">{{ $t("供应") }}：</span>
            <!-- <span v-if="detailData.specialMeal.indexOf('1') != -1">{{ $t('提供宗教餐') }}</span> -->
            <span v-if="detailData.specialMeal.indexOf('4') != -1">{{ $t("提供僧侣料理需提前预约") }})</span>
            <span v-if="detailData.specialMeal.indexOf('2') != -1">{{
              $t("提供僧侣料理")
            }}</span>
            <span v-if="detailData.specialMeal.indexOf('3') != -1">{{
              $t("提供清真料理")
            }}</span>
            <span v-if="detailData.specialMeal.indexOf('5') != -1">{{ $t("提供清真料理需提前预约") }})</span>
          </div>
          <div v-if="detailData.treeId === 1" class="mt-10">
            <span class="title-text">{{ $t("店铺地址") }}：</span>
            <span v-if="detailData.address" class="address-text lh-16">{{
              detailData.address
            }}</span>
            <span v-else>-</span>
          </div>
          <div v-if="detailData.treeId === 2 && detailData.address" class="mt-10">
            <span class="title-text">{{ $t("店铺地址") }}：</span>
            <span v-if="detailData.address" class="address-text lh-16">{{
              detailData.address
            }}</span>
            <span v-else>-</span>
          </div>
          <div class="mt-10">
            <l-map class="content-map" :zoom="zoom" :center="center" @update:zoom="zoomUpdated" :crs="crs" @update:center="centerUpdated" @update:bounds="boundsUpdated">
              <l-tile-layer :url="url" :subdomains="subdomains" :options="tileLayerOptions" :tms="tms"></l-tile-layer>
              <l-marker :lat-lng="marker" :icon="addressIcon" :attribution="attribution">
                <!-- <l-icon :icon-anchor="[32, 32]" :icon-size="[32, 32]" :icon-url="iconUrl"></l-icon> -->
                <l-popup>{{ detailData.storeName }}</l-popup>
              </l-marker>
            </l-map>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import { formatMoney } from '@/utils/money'
import { lanMap, dealWeekDay } from '@/utils/util'
// import { getLanguage } from '@/lang/index'
import { weeks, storeShopType, storeService2, storePayType, measuresList, onlinePayType, qrPayType, getDefaultLon, getDefaultLat } from '@/utils/public_data'
import { latLng, icon } from 'leaflet'
import L from 'leaflet'
import 'proj4'
import 'proj4leaflet'
import 'leaflet.chinatmsproviders'
import i18n from '@/lang/index'
// LIcon,
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet'
import Swiper from 'swiper'

export default {
  // LIcon
  components: { LMap, LTileLayer, LMarker, LPopup },
  filters: {
    moneyFilter(val) {
      return formatMoney(val)
    },
    timeFilter(val) {
      return val.substring(0, 5)
    },
  },
  data() {
    return {
      storeId: 0,
      menus: [
        { value: 1, label: '景点总览' },
        { value: 2, label: '餐厅SNS' },
        { value: 3, label: '防疫对策' },
      ],
      detailData: {},
      // center: { lng: 139.764887, lat: 35.682084 },
      zooms: 15,
      active: 1,
      showFlag: false,
      weeks: weeks(),
      shopType: storeShopType(),
      service: storeService2(),
      payType: storePayType(),
      onlineType: onlinePayType(),
      qrType: qrPayType(),
      facebook: require('@/assets/images/media-01.png'),
      youtube: require('@/assets/images/media-02.png'),
      twitter: require('@/assets/images/media-05.png'),
      ins: require('@/assets/images/media-03.png'),
      tictok: require('@/assets/images/media-04.png'),
      dataLoading: false,
      BMap: null,
      map: null,
      mapShow: false,
      thumbsSwiper: {},
      swiper: {},
      zoom: 14,
      center: latLng(getDefaultLat(), getDefaultLon()),
      url: 'http://www.google.jp/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}',
      attribution: '&copy; <a href="http://www.google.jp">Google</a>',
      currentZoom: 11.5,
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
      },
      crs: L.CRS.EPSG3857,
      tms: false,
      subdomains: '',
      tileLayerOptions: {
        maxZoom: 18,
        minZoom: 8,
      },
      marker: [],
      iconUrl: require('@/assets/images/address.png'),
      addressIcon: icon({
        iconUrl: require('@/assets/images/address.png'),
        iconSize: [32, 32],
        iconAnchor: [16, 10],
      }),
      showDetail: false,
      showMap: true,
      videoShow: false,
      videoPlay: null,
    }
  },
  created() {
    lanMap(this, this.lan)
  },
  mounted() {
    // window.addEventListener("scroll", this.showTop);
    // this.$refs.ContainerViews.scrollIntoView({block: "start"})
  },
  computed: {
    lan() {
      return i18n.locale
    },
  },
  watch: {
    center: {
      handler(newVal) {
        console.log(newVal)
      },
      deep: true,
    },
    lan(newVal) {
      lanMap(this, newVal)
    },
  },
  methods: {
    handle(val) {
      this.$router.push(val)
    },
    dealWeekDays(list) {
      // console.log(dealWeekDay(list))
      return dealWeekDay(list)
    },
    zoomUpdated(zoom) {
      this.zoom = zoom
    },
    centerUpdated(center) {
      this.center = center
    },
    boundsUpdated(bounds) {
      this.bounds = bounds
    },
    initSwiper() {
      this.swiper = new Swiper(`.img-top`, {
        spaceBetween: 10,
        nextButton: `.swiper-button-next`,
        prevButton: `.swiper-button-prev`,
        speed: 1000,
        loop: false,
        pagination: {
          el: '.swiper-pagination',
        },
      })
    },
    init(data) {
      this.dataLoading = true
      this.mapShow = false
      if (data) {
        this.detailData = data
        let minPrice = this.detailData.breakfast
        let maxPrice = this.detailData.lunch
        if (minPrice == 0 || minPrice > this.detailData.dinner) {
          minPrice = this.detailData.dinner
        }
        if (minPrice == 0 || minPrice > this.detailData.lunch) {
          minPrice = this.detailData.lunch
        }
        if (maxPrice == 0 || maxPrice < this.detailData.dinner) {
          maxPrice = this.detailData.dinner
        }
        if (maxPrice == 0 || maxPrice < this.detailData.breakfast) {
          maxPrice = this.detailData.breakfast
        }
        this.$set(this.detailData, 'minPrice', minPrice)
        this.$set(this.detailData, 'maxPrice', maxPrice)
        if (this.detailData.storeImg) {
          this.$set(this.detailData, 'imgList', this.detailData.storeImg.split(','))
          if (this.detailData.imgList && this.detailData.imgList.length > 0) {
            this.$nextTick(() => {
              this.initSwiper()
            })
          }
          console.log('img', this.detailData.imgList)
        }
        if (this.detailData.storeType) {
          this.$set(this.detailData, 'storeTypes', this.detailData.storeType.split(','))
        }
        if (this.detailData.stipulationRestDays) {
          // eslint-disable-next-line no-useless-escape
          var stipulationRestDaysArr = this.detailData.stipulationRestDays.split('//')
          this.$set(
            this.detailData,
            'restDay',
            stipulationRestDaysArr[0].replace('undefined', '').replace(/^,+/, '').split(',').concat(stipulationRestDaysArr[1])
          )
        }
        if (this.detailData.allowPayType) {
          this.$set(this.detailData, 'payTypes', this.detailData.allowPayType.split(','))
        }
        if (this.detailData.allowOnlinePay) {
          this.$set(this.detailData, 'onlineTypes', this.detailData.allowOnlinePay.split(','))
        }
        if (this.detailData.allowQrPay) {
          this.$set(this.detailData, 'qrTypes', this.detailData.allowQrPay.split(','))
        }
        if (this.detailData.storeDescribe) {
          this.$set(this.detailData, 'measures', this.detailData.storeDescribe.split(','))
        }
        if (this.detailData.storeBusinessTimeDtoList && this.detailData.storeBusinessTimeDtoList.length > 0) {
          this.detailData.storeBusinessTimeDtoList.map((item) => {
            this.$set(item, 'weeks', item.week.split(',').sort())
          })
          let startTime = this.detailData.storeBusinessTimeDtoList[0].startTime
          let endTime = this.detailData.storeBusinessTimeDtoList[0].endTime
          if (startTime && endTime) {
            this.$set(this.detailData, 'businessTimes', startTime + '~' + endTime)
          }
        }
        if (this.detailData.latitude && this.detailData.longitude) {
          this.mapShow = true
        }
        if (this.detailData.videoUrl) {
          this.videoShow = false
          this.$set(this.detailData, 'videoList', this.detailData.videoUrl.split(','))
          if (this.detailData.videoList && this.detailData.videoList.length > 0) {
            this.menus.unshift({ value: 4, label: this.$t('景点视频') })
            this.active = 4
            this.videoPlay = this.detailData.videoList[0]
            this.videoShow = true
          }
        }
        if (this.detailData.storeDescription) {
          let result = this.detailData.storeDescription
          const regex = new RegExp('<img', 'gi')
          result = result.replace(regex, `<img style="width: 100%;display:block;margin-left:0;"`)
          result = result.replace(/\n/g, '<br/>')
          this.detailData.storeDescription = result
        }
        // mtMap.centerAndZoom(new this.BMap.Point(this.detailData.latitude,this.detailData.longitude),11)
        console.log('this.detailData', this.detailData)
        this.center = latLng(this.detailData.latitude, this.detailData.longitude)
        this.marker = [this.detailData.latitude, this.detailData.longitude]
        // this.marker.push(this.detailData.latitude)
        // this.marker.push(this.detailData.longitude)
        this.dataLoading = false
      }
    },
    changeMenu(value) {
      this.active = value
      // 这里可以进行页面的跳动
      // this.$refs.FirstStep.scrollIntoView({block: "start"})
      if (this.active === 1) {
        this.$refs.StoreInfo.scrollIntoView({ block: 'center' })
      }
      if (this.active === 2) {
        this.$refs.Social.scrollIntoView({ block: 'center' })
      }
      if (this.active === 3) {
        this.$refs.Infection.scrollIntoView({ block: 'center' })
      }
    },
    measuresName(measure) {
      var measures = measuresList()
      for (var i = 0; i < measures.length; i++) {
        if (measures[i].value === measure) {
          return measures[i].label
        }
      }
      return ''
    },
    getRestDay(restItem) {
      if (this.weeks[restItem]) {
        return this.weeks[restItem]
      } else {
        return restItem
      }
    },
    getYoutubeUrl(videoId) {
      if (videoId.indexOf('http') === 0) {
        return videoId
      }
      return 'https://www.youtube.com/embed/' + videoId
    },
    // 控制menu的出现或消失
    showTop() {
      if (!!document.documentElement.scrollTop && document.documentElement.scrollTop > 300) {
        this.showFlag = true
      } else {
        this.showFlag = false
      }
    },
    // 获取地址label样式，英文环境处理
    getAddressLabelStyle() {
      if (this.$i18n.locale === 'en') {
        return 'width: 76px;'
      }
      return 'width: 50px;'
    },
    // 禁烟类型
    getSmokingType(smokingType) {
      var s = ''
      if (!smokingType) {
        return s
      }
      if (smokingType.toString().indexOf('1') > -1) {
        s += this.$t('禁止吸烟')
      }
      if (smokingType.toString().indexOf('2') > -1) {
        if (s.length > 0) {
          s += ','
        }
        s += this.$t('店内可吸烟')
      }
      if (smokingType.toString().indexOf('3') > -1) {
        s += '(' + this.$t('有吸烟室') + ')'
      }
      if (smokingType.toString().indexOf('4') > -1) {
        if (s.length > 0) {
          s += ','
        }
        s += this.$t('禁止吸烟')
      }
      return s
    },
  },
}
</script>
<style lang="scss" scoped>
.line-box {
  width: 100%;
  height: 20px;
  background: rgb(245, 242, 245);
}
.top-crumb {
  border-top: 1px solid #dcdfe6;
  padding: 6px 0px 10px 10px;
}
.img-box {
  width: 100%;
  height: 200px;
  .img-top {
    width: 100%;
    height: 200px;
    .list-image {
      width: 100%;
    }
  }
}
.contain {
  padding-top: 30px;
  padding-bottom: 10px;
  width: 100%;
  min-height: 100px;
  box-sizing: border-box;
  .content-title {
    font-size: 18px;
    font-weight: 600;
  }
  .content-rate {
    margin: 8px 0 10px;
    ::v-deep .el-rate__icon {
      font-size: 24px;
    }
    ::v-deep .el-rate__text {
      font-size: 18px;
    }
  }
  .content-time {
    display: flex;
    flex-wrap: wrap;
  }
  .content-money {
    width: 100%;
    .cost-text {
      color: #f56c6c;
    }
    // display: flex;
    // flex-wrap: wrap;
  }
  .content-discount {
    width: 100%;
    background: #fdf6eb;
    .cost-discount {
      padding: 4px;
      line-height: 16px;
      .const-red-content {
        color: #f56c6c;
      }
    }
  }
  .content-map {
    width: 100%;
    height: 200px;
  }
}
.menu-box1 {
  margin-top: 30px;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #dcdfe6;
}
.menu-list {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  .menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin: 0 10px;
    font-size: 14px;
    font-weight: 600;
    color: #2d333f;
  }
  .menu-active {
    color: #e7b530;
    border-bottom: 3px solid #e7b530;
  }
}
.store-info {
  width: 100%;
  .store-title {
    width: 100%;
    margin: 30px 0 40px;
    border-left: 3px solid #e7b530;
    padding-left: 20px;
    font-size: 18px;
    font-weight: 600;
  }
  .detail-content {
    width: 100%;
    img {
      width: 100%;
      height: 200px;
    }
  }
}
.social-media {
  width: 100%;
  .social-title {
    margin: 30px 0 40px;
    border-left: 3px solid #e7b530;
    padding-left: 20px;
    font-size: 18px;
    font-weight: 600;
  }
  .social-content {
    width: 100%;
    min-height: 100px;
    .social-list {
      display: flex;
      justify-content: flex-start;
      .social-item {
        margin: 10px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        .social-logo {
          margin-bottom: 10px;
          width: 40px;
        }
        .social-text {
          font-size: 12px;
        }
        &:hover {
          .social-text {
            color: #e7b530;
          }
        }
      }
    }
  }
}
.infection-info {
  width: 100%;
  .infection-title {
    margin: 30px 0 40px;
    border-left: 3px solid #e7b530;
    padding-left: 20px;
    font-size: 18px;
    font-weight: 600;
  }
}
.mt-20 {
  margin-top: 20px;
}
.mt-10 {
  margin-top: 10px;
}
.mr-20 {
  margin-right: 20px;
}
.flex-box {
  display: flex;
  flex-wrap: wrap;
  line-height: 16px;
}
.mb-10 {
  margin-bottom: 10px;
}
.title-text {
  font-weight: 600;
}
.lh-16 {
  line-height: 16px;
}
.go-to-box {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-direction: column;
  color: #4fb096;
  // border: 1px solid rgb(137, 186, 196);
  background: #e8f8f0;
  // width: 100%;
  border-radius: 2px;
  // height: 30px;
  font-size: 12px;
  display: inline-block;
  padding: 4px;
  // font-weight: 700;
  }
.moreBut{
  width: 100%;
  // margin-bottom: 10px;
  margin-top: 10px;
  font-size: 16px;
  color: #fff;
  border: rgb(227, 178, 76);
  background-color: rgb(227, 178, 76);
}
.video-img-list {
  margin-top: 6px;
}
.video-select {
  border: 1px solid #ffb937;
  box-sizing: border-box;
}
</style>
